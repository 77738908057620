// @ts-nocheck
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLazyGetSellerQuery } from "../../service/Home";
import { useEffect, useState } from "react";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import { toggleAuthModal } from "../../reducers/generalSlice";
import useTranslation from "../../hooks/Translation";

const Officialstores = () => {
  const user = useAuth();
  const translation = useTranslation() as any;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [sellers, setSellers] = useState([]);
  const [getSellers] = useLazyGetSellerQuery();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);

  const CustomNextArrow = ({ onClick }) => (
    <div
      className="custom-arrow next-arrow"
      onClick={() => onPageChange(page + 1)}
    >
      <ArrowForwardIosIcon />
    </div>
  );

  const CustomPrevArrow = ({ onClick }) => (
    <div
      className="custom-arrow prev-arrow"
      onClick={() => onPageChange(page - 1)}
    >
      <ArrowBackIosIcon />
    </div>
  );

  const settin = {
    dots: false,
    arrows: sellers?.length > 1,
    infinite: false,
    slidesToScroll: 1,
    rows: 3,
    slidesPerRow: 4,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          rows: 2,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          rows: 1,
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesPerRow: 1,
        },
      },
    ],
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const handleVisitSeller = (id) => {
    if (user) {
      navigate(`/view-profile/${id}`);
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
      );
    }
  };

  const fetchData = async () => {
    try {
      const response = await getSellers({
        page: page,
        size: 24,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSellers(response?.data?.data);
        setCount(response?.data?.count);
      } else {
        setSellers([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    fetchData();
  }, [page]);

  return (
    <>
      {sellers?.length ? (
        <section className="prodcts_sc container ofclstr">
          <div className="prdct_hdngs ">
            <h2>{translation.Globals.official_stores}</h2>
            <div onClick={() => navigate("/official-stores")}>
              <p>{translation.Globals.see_all}</p>
            </div>
          </div>
          <div className="arrival_flex ">
            <Slider {...settin}>
              {sellers?.length
                ? sellers?.map((item) => {
                    return (
                      <li key={item?._id}>
                        <div className="prdct_crd prdct_str">
                          <figure className="prdct_img">
                            <LazyLoadImage
                              alt="prdct_img"
                              src={
                                item?.logoImage?.length
                                  ? item?.logoImage
                                  : "/static/images/picture.png"
                              }
                              effect="blur"
                              height={"100%"}
                              width={"100%"}
                            />
                          </figure>
                          <div className="prdct_dtl">
                            <h3>{item?.businessName || ""}</h3>
                            <h6
                              style={{
                                fontSize: "14px",
                                fontWeight: 500,
                                color: "#0101af",
                              }}
                              onClick={() =>
                                handleVisitSeller(item?.userId?._id)
                              }
                            >
                              {translation.Globals.visit_store}{" "}
                            </h6>
                          </div>
                        </div>
                      </li>
                    );
                  })
                : null}
            </Slider>
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Officialstores;

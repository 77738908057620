// @ts-nocheck
import React, { useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";

import "react-datepicker/dist/react-datepicker.css";

import { Button, InputField } from "../../components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import "./addproduct.scss";
import LogoHeader from "../../layout/logoHeader";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isNumber, isString } from "../../utils/validations";

import {
  CardRes,
  CommonBody,
  Subcategories,
  boosterPlans,
  globalSearch,
} from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useAddBoosterMutation,
  useAddProductMutation,
  useLazyGetBoosterQuery,
  useLazyGetMyProductByIdQuery,
  useUpdateProductMutation,
} from "../../service/Product";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import ClearIcon from "@mui/icons-material/Clear";
import { useLazyGetSubCategoriesQuery } from "../../service/category";
import { UploadMedia } from "../../utils/uploadMedia";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import useAuth from "../../hooks/useAuth";
import { Colors } from "../../assets";
import useTranslation from "../../hooks/Translation";
import {
  useLazyGetCardQuery,
  useSaveCardMutation,
} from "../../service/payments";
import { STRIPE_TEST_KEY } from "../../helpers/constants/urls";

let links: string[] = [];
const AddProduct = () => {
  const language = getFromStorage(STORAGE_KEYS.language);

  const translation = useTranslation() as any;
  const userData = useAuth();
  const style = generateResponsiveStyle();
  const { id } = useParams();
  const { prodId } = useParams();

  const [updateProduct] = useUpdateProductMutation();
  const navigate = useNavigate();
  const [addProduct] = useAddProductMutation();
  const [getBoosterMutation] = useLazyGetBoosterQuery();
  const [addBoosterMutation] = useAddBoosterMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [openAddCard, setOpenAddCard] = useState<boolean>(false);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [productById] = useLazyGetMyProductByIdQuery();

  const [myImages, setMyImages] = useState<string[]>([]);
  const [imageObjects, setImageObjects] = useState<File[]>([]);
  const [uploadedImagesCount, setUploadedImagesCount] = useState(0);

  const [subCat, setSubCat] = useState<string>("");
  const [booseterCheck, setBoosterCheck] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [makeOffer, setMakeOffer] = useState(true);
  const [subCategory] = useLazyGetSubCategoriesQuery();
  const [subCategories, setSubCategories] = useState<Subcategories[]>([]);
  const [booster, setBooster] = useState<boosterPlans[]>([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [boosterPrice, setBoosterPrice] = useState<number>();

  const [error, setError] = useState(false);
  const [getPropertyData, setGetPropertyData] = useState<globalSearch>();

  const [brand, setBrand] = useState<string>("");
  const [cards, setCards] = useState<CardRes[]>([]);
  const [selectedCard, setSelectedCard] = useState("");

  const [addCard] = useSaveCardMutation();
  const [getCards] = useLazyGetCardQuery();

  const getAllCards = async () => {
    try {
      const response = await getCards({}).unwrap();
      if (response?.statusCode === 200) {
        setCards(response?.data?.data || []);
      } else {
        setCards([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const [selectedSubcategory, setSelectedSubcategory] =
    useState<Subcategories | null>(null);

  const [autocomplete, setAutocomplete] = useState(null);

  const handleBrandChange = (event: SelectChangeEvent) => {
    setBrand(event.target.value as string);
  };

  // Generate initial values and validation schema dynamically
  const generateInitialValuesAndSchema = () => {
    const initialValues = {};
    const validationSchemaFields = {};

    if (prodId) {
      if (language === "en") {
        for (
          let index = 0;
          index < getPropertyData?.dynamicFields.length;
          index++
        ) {
          const field2 = getPropertyData?.dynamicFields[index];

          const fieldName = Object.keys(field2)[0];
          const fieldValues = Object.values(field2)[0];
          initialValues[fieldName] = fieldValues || "";
          validationSchemaFields[fieldName] = Yup.string().required(
            `${translation.validations.required_field}`
          );
        }
      } else {
        for (
          let index = 0;
          index < getPropertyData?.dynamicFields_ar.length;
          index++
        ) {
          const field2 = getPropertyData?.dynamicFields_ar[index];

          const fieldName = Object.keys(field2)[0];
          const fieldValues = Object.values(field2)[0];
          initialValues[fieldName] = fieldValues || "";
          validationSchemaFields[fieldName] = Yup.string().required(
            `${translation.validations.required_field}`
          );
        }
      }
    } else {
      if (language === "en" && selectedSubcategory?.brand?.length) {
        for (
          let index = 1;
          index < selectedSubcategory?.dynamicFields.length;
          index++
        ) {
          const field = selectedSubcategory?.dynamicFields[index];
          const fieldName = Object.keys(field)[0];
          initialValues[fieldName] = "";
          validationSchemaFields[fieldName] = Yup.string().required(
            `${translation.validations.required_field}`
          );
        }
      } else if (
        language === "en" &&
        selectedSubcategory?.brand?.length === 0
      ) {
        for (
          let index = 0;
          index < selectedSubcategory?.dynamicFields.length;
          index++
        ) {
          const field = selectedSubcategory?.dynamicFields[index];

          const fieldName = Object.keys(field)[0];

          initialValues[fieldName] = "";
          validationSchemaFields[fieldName] = Yup.string().required(
            `${translation.validations.required_field}`
          );
        }
      } else {
        for (
          let index = 1;
          index < selectedSubcategory?.dynamicFields_ar.length;
          index++
        ) {
          const field = selectedSubcategory?.dynamicFields_ar[index];

          const fieldName = Object.keys(field)[0];

          initialValues[fieldName] = "";
          validationSchemaFields[fieldName] = Yup.string().required(
            `${translation.validations.required_field}`
          );
        }
      }
    }

    return {
      initialValues,
      validationSchema: Yup.object(validationSchemaFields),
    };
  };

  const { initialValues, validationSchema } = generateInitialValuesAndSchema();

  const handleSortingOptions = (event: any) => {
    setSelectedValue(event.target.value);
  };

  //formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      productName: getPropertyData?.name || "",
      price: getPropertyData?.price || "0",
      description: getPropertyData?.description || "",
      quantity: getPropertyData?.totalQuantity || "",
      lat: "",
      long: "",
      state: "",
    },

    validationSchema: validationSchema.shape({
      productName: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.min_three),
      price: Yup.string().required(translation.validations.required_field),
      description: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.min_three),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!subCat) {
        setError(true);
        return;
      }

      setLoading(true);
      if (uploadedImagesCount > 0) {
        links = [...myImages?.slice(0, uploadedImagesCount)];
      } else {
        links = [];
      }

      for (let index = 0; index < imageObjects.length; index++) {
        const item = imageObjects[index];
        const res = await UploadMedia(item);

        if (res?.statusCode === 200) {
          links.push(res?.data || "");
        } else {
          setLoading(false);
        }
      }

      setError(false);
      if (selectedValue) {
        setOpen(true);
        return;
      }
      if (!links?.length) {
        errorToast(translation.toast_messages.image_required);
        return;
      }
      let dynamicFieldsArraay;

      if (language === "en") {
        dynamicFieldsArraay = selectedSubcategory?.brand?.length
          ? selectedSubcategory?.dynamicFields
              .slice(1) // Skip the first dynamic field
              .map((field) => {
                const fieldName = Object.keys(field)[0];
                return {
                  name: fieldName,
                  value: formik.values[fieldName],
                };
              })
          : selectedSubcategory?.dynamicFields.map((field) => {
              const fieldName = Object.keys(field)[0];

              return {
                name: fieldName,
                value: formik.values[fieldName],
              };
            });
      } else {
        dynamicFieldsArraay = selectedSubcategory?.dynamicFields_ar
          // .slice(1) // Skip the first dynamic field
          .map((field) => {
            const fieldName = Object.keys(field)[0];

            return {
              name: fieldName,
              value: formik.values[fieldName],
            };
          });
      }
      const transformedArray = dynamicFieldsArraay?.map(({ name, value }) => ({
        [name]: value,
      }));
      const data = {
        name: formik.values.productName,
        description: formik.values.description,
        totalQuantity: formik.values.quantity,
        price: formik.values.price,
        makeAnOffer: makeOffer,
        images: links,
        state: formik.values.state,
        categoryId: id || "",
        subCategoryId: subCat || "",
        dynamicFields: transformedArray,
        brand: brand || "",
        lang: language === "en" ? 1 : 2,
        ...(prodId ? { approvedByAdmin: 1 } : null),
      };

      setLoading(true);
      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        if (selectedValue) {
          setOpen(true);
          return;
        }
        if (!prodId) {
          const res = await addProduct(encryptedBody).unwrap();
          if (res?.statusCode === 200) {
            setLoading(false);
            if (res?.data) {
              successToast(translation.toast_messages.product_added);
              formik.resetForm();
              setMyImages([]);
              setSubCat("");
              setBrand("");
              setError(false);
              navigate("/profile", { state: "listing" });
            }
          }
        } else {
          const res = await updateProduct({
            id: prodId,
            encryptedBody,
          }).unwrap();
          if (res?.statusCode === 200) {
            setLoading(false);

            if (res?.data) {
              successToast(translation.toast_messages.product_updated);
              formik.resetForm();
              setMyImages([]);
              setSubCat("");
              setBrand("");
              setError(false);
              navigate("/profile", { state: "listing" });
            }
          }
        }
      } catch (error: any) {
        setLoading(false);
        errorToast(error?.data?.message || "");
      }

      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as any)["Stripe"].setPublishableKey(STRIPE_TEST_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);

  const paymentFormik = useFormik({
    initialValues: {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string()
        .required(translation.validations.required_field)
        .min(3, translation.validations.card_holder_name)
        .max(20),
      cardNumber: Yup.string()
        .required(translation.validations.required_field)
        .label("Card number")
        .min(16, translation.validations.cardNo_val),
      expiryDate: Yup.string().required(translation.validations.required_field),
      cvv: Yup.string()
        .required(translation.validations.required_field)
        .label("CVV")
        .min(3, translation.validations.cvv_val),
    }),

    onSubmit: (values) => {
      paymentFormik.setSubmitting(true);

      if (window?.Stripe) {
        // @ts-ignore
        window?.Stripe.card.createToken(
          {
            number: paymentFormik.values.cardNumber?.slice(0, 19),
            exp_month: String(paymentFormik.values.expiryDate).slice(0, 2),
            exp_year: String(paymentFormik.values.expiryDate).slice(-2),
            cvc: paymentFormik.values.cvv?.trim(),
            name: paymentFormik.values.cardHolderName?.trim(),
          },
          async (status: number, response: any) => {
            if (status === 200) {
              let body = {
                token: response?.id,
              };
              try {
                let encryptedBody = generateEncryptedKeyBody(
                  body
                ) as CommonBody;

                const res = await addCard(encryptedBody).unwrap();
                if (res?.statusCode === 200) {
                  if (res?.data) {
                    successToast("Card added successfully");
                    setOpenAddCard(false);
                    setOpen(true);
                    getAllCards();
                    paymentFormik.resetForm();
                  }
                }
              } catch (error: any) {
                errorToast(error?.data?.message || "");
              }
            } else {
              errorToast(response.error.message);
            }
          }
        );
      }

      formik.setSubmitting(false);
    },
  });
  const cardImage = (cardType: string) => {
    switch (cardType?.toLowerCase()) {
      case "visa":
        return "/static/images/visa.png";
      case "electron":
        return "/static/images/electron.png";
      case "maestro":
        return "/static/images/maestro.png";
      case "dankort":
        return "/static/images/dankort.png";
      case "interpayment":
        return "/static/images/interpayment.png";
      case "unionpay":
        return "/static/images/unionpay.png";
      case "mastercard":
        return "/static/images/mastercard.png";
      case "american express":
        return "/static/images/amex.png";
      case "diners club":
        return "/static/images/dinners.png";
      case "discover":
        return "/static/images/discover.png";
      case "jcb":
        return "/static/images/jcb.png";
      default:
        return "/static/images/visa.png";
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const invalidFiles = Array.from(files).filter(
        (file) => !allowedTypes.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        errorToast(translation.Globals.invalidImg);
        return;
      }
      if (myImages?.length + files.length > 6) {
        errorToast(translation.toast_messages.max6 || "");
        return;
      }
      const uploadedImages = Array.from(files).map((file) =>
        URL.createObjectURL(file)
      );

      setMyImages((prevImages: string) => [...prevImages, ...uploadedImages]);
      setImageObjects((prevFiles: File) => [...prevFiles, ...files]);
    }
  };

  const handleCrossClick = async (e: any, i: number) => {
    e.stopPropagation();
    if (i >= uploadedImagesCount) {
      let arr1 = [...imageObjects];
      arr1?.splice(i - uploadedImagesCount, 1);
      setImageObjects([...arr1]);
    } else {
      setUploadedImagesCount(uploadedImagesCount - 1);
    }
    let arr = [...myImages];
    arr?.splice(i, 1);
    setMyImages([...arr]);
  };

  const handleSubCategoryChange = (event: SelectChangeEvent) => {
    setSubCat(event.target.value as string);

    const selectedSubcategory = subCategories.find(
      (item: Subcategories) => item?._id === event.target.value
    );

    setSelectedSubcategory(selectedSubcategory || null);
  };

  const getSubCategory = async (id: string) => {
    try {
      const response = await subCategory({
        categoryId: id,
        page: 1,
        limit: 50,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategories(response?.data?.subCategory || []);
      } else {
        setSubCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getProductById = async (id: string) => {
    try {
      const res = await productById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setGetPropertyData(res?.data);
        setMakeOffer(res?.data?.makeAnOffer);
        setSubCat(res?.data?.subCategoryId || "");
        setBrand(res?.data?.brand || "");
        links = res?.data?.images || [];
        if (subCategories) {
          setSelectedSubcategory(
            subCategories?.find(
              (item: Subcategories) => item?._id === res?.data?.subCategoryId
            )
          );
        }
        const transSimpleArray = res?.data?.dynamicFields?.map((obj) => {
          const [name] = Object.keys(obj);
          const value = obj[name];
          return { name, value };
        });

        if (transSimpleArray && transSimpleArray.length > 0) {
          const dynamicFieldsValues = {};
          transSimpleArray.forEach(({ name, value }) => {
            dynamicFieldsValues[name] = value;
          });

          formik.setValues((prevValues) => ({
            ...prevValues,
            ...dynamicFieldsValues,
          }));
        }

        if (res?.data?.images?.length) {
          if (res?.data?.images?.length && myImages?.length === 0) {
            setMyImages([...res?.data?.images]);
            setUploadedImagesCount(res?.data?.images?.length);
          }
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getBooster = async () => {
    try {
      const result = await getBoosterMutation({}).unwrap();
      if (result?.statusCode === 200) {
        setBooster(result?.data?.boosterPlans || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddProductWidthBooster = async () => {
    setLoading(true);
    await Promise.all(
      myImages?.map(async (item, index) => {
        if (item?.path?.includes("s3.ap-south-1.amazonaws.com")) {
          links[index] = item?.path || "";
        } else {
          const res = await UploadMedia(item?.file);

          if (res?.statusCode === 200) {
            setLoading(false);
            links[index] = res?.data || "";
          }
        }
      })
    );

    links.sort((a, b) => {
      return (
        myImages.findIndex((item) => item.path === a) -
        myImages.findIndex((item) => item.path === b)
      );
    });

    if (!subCat) {
      setError(true);
      return;
    }
    if (!links?.length) {
      errorToast(translation.toast_messages.image_required);
      return;
    }
    setError(false);
    setLoading(false);

    const dynamicFieldsArraay = selectedSubcategory?.dynamicFields
      .slice(1) // Skip the first dynamic field
      .map((field) => {
        const fieldName = Object.keys(field)[0];
        return {
          name: fieldName,
          value: formik.values[fieldName],
        };
      });

    const transformedArray = dynamicFieldsArraay?.map(({ name, value }) => ({
      [name]: value,
    }));
    const data = {
      name: formik.values.productName,
      description: formik.values.description,
      totalQuantity: formik.values.quantity,
      price: formik.values.price,
      makeAnOffer: makeOffer,
      images: links,
      categoryId: id || "",
      subCategoryId: subCat || "",
      dynamicFields: transformedArray,
      brand: brand || "",
      lang: language === "en" ? 1 : 2,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const res = await addProduct(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        if (res?.data) {
          successToast(translation.toast_messages.product_added);
          formik.resetForm();
          setMyImages([]);
          setSubCat("");
          setBrand("");
          setError(false);
          navigate("/profile", { state: "listing" });
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const handleBoosterCheckClick = () => {
    setBoosterCheck(!booseterCheck);
    if (booseterCheck === true) {
      setSelectedValue("");
    } else if (booseterCheck === false) {
      setSelectedValue(booster[0]?._id || "");
      setBoosterPrice(booster[0]?.price || null);
    }
  };

  const buyBooster = async (id: string) => {
    const data = {
      card: selectedCard,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const result = await addBoosterMutation({ id, encryptedBody }).unwrap();
      if (result?.statusCode === 200) {
        setOpen(false);
        handleAddProductWidthBooster();
        successToast(result?.message || "");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeDescription = (event) => {
    const { value } = event.target;

    const phonePattern = /[0-9]{6}/;

    if (value.includes("@") || phonePattern.test(value)) {
      errorToast("You cannot enter an email or phone number here.");
      return;
    }
    formik.handleChange(event);
  };

  useEffect(() => {
    if (prodId) {
      getProductById(prodId);
    }
  }, [prodId, subCategories]);

  useEffect(() => {
    if (id) {
      setSelectedSubcategory(null);
      getSubCategory(id);
    }
  }, [id]);

  useEffect(() => {
    getBooster();
    getAllCards();
  }, []);

  return (
    <>
      <Loader isLoad={loading} />
      <LogoHeader />
      <div className="wrapCls">
        <div className="container">
          <div className="addProduct">
            <h2>{translation.add_product.almost_there}</h2>
            <h5>{translation.add_product.set_price}</h5>
            <ul>
              {myImages?.length > 5 ? null : (
                <label htmlFor="icon-button-files">
                  <div className="uploader">
                    <input
                      style={{ display: "none" }}
                      id="icon-button-files"
                      type="file"
                      onChange={handleFileUpload}
                      accept=".png, .jpg, .jpeg"
                      multiple
                    />
                    <AddIcon />
                    <h3>{translation.add_product.add_pictue}</h3>
                  </div>
                </label>
              )}
              {myImages?.length ? (
                <>
                  {myImages?.map((item, i) => {
                    return (
                      <li key={i}>
                        <ClearIcon
                          className="cross"
                          onClick={(e) => handleCrossClick(e, i)}
                        />
                        <figure>
                          <img src={item} alt="upload" />
                        </figure>
                      </li>
                    );
                  })}
                </>
              ) : null}
            </ul>

            {userData?.boosterBought ||
            prodId ||
            userData?.role === "ADMIN" ? null : (
              <div className="booster">
                <div className="boosterdiv">
                  <h3>{translation.add_product.buy_booster}</h3>
                  <FormControlLabel
                    control={<Checkbox />}
                    checked={booseterCheck}
                    name="remember"
                    onChange={handleBoosterCheckClick}
                  />
                </div>
                {booseterCheck ? (
                  <div>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={selectedValue}
                      onChange={handleSortingOptions}
                    >
                      {booster &&
                        booster?.map((option: boosterPlans) => (
                          <FormControlLabel
                            className="rado_label"
                            key={option._id}
                            value={option._id}
                            control={
                              <Radio
                                onClick={() => setBoosterPrice(option?.price)}
                              />
                            }
                            label={
                              <div>
                                <span>
                                  {translation.Globals.for}{" "}
                                  {language === "en" ? (
                                    <>
                                      {option?.validity === 1
                                        ? `1 week`
                                        : option?.validity === 2
                                        ? `2 weeks`
                                        : option?.validity === 3
                                        ? `3 months`
                                        : option?.validity === 4
                                        ? `6 months`
                                        : option?.validity === 5
                                        ? `9 months`
                                        : option?.validity === 6
                                        ? `1 year`
                                        : ""}
                                    </>
                                  ) : (
                                    <>{option?.validity_ar}</>
                                  )}
                                </span>
                                <span style={{ color: Colors.secondary }}>
                                  {" "}
                                  {translation.Globals.aed} {option?.price}
                                </span>
                              </div>
                            }
                            labelPlacement="start"
                          />
                        ))}
                    </RadioGroup>
                  </div>
                ) : null}
              </div>
            )}

            <form onSubmit={formik.handleSubmit}>
              <FormControl fullWidth>
                <Select
                  className="select_add"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={subCat}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleSubCategoryChange}
                >
                  <MenuItem value="" disabled>
                    {translation.add_product.select_sub}
                  </MenuItem>
                  {subCategories?.length
                    ? subCategories?.map((item: Subcategories) => (
                        <MenuItem key={item?._id} value={item?._id}>
                          {language === "ar"
                            ? item?.name_ar || ""
                            : item?.name || ""}
                        </MenuItem>
                      ))
                    : null}
                </Select>
                {error && !subCat ? (
                  <h6 className="err_msg">
                    {translation.validations.required_field}
                  </h6>
                ) : (
                  ""
                )}
              </FormControl>
              {/* brand English*/}
              {language === "en" ? (
                selectedSubcategory && selectedSubcategory?.brand?.length ? (
                  <FormControl fullWidth>
                    <Select
                      className="select_add"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={brand}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleBrandChange}
                    >
                      <MenuItem value="" disabled>
                        {translation.add_product.select_brand}
                      </MenuItem>
                      {selectedSubcategory?.brand?.map(
                        (item: string, i: number) => (
                          <MenuItem key={i} value={item}>
                            {item || ""}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {error && !brand ? (
                      <h6 className="err_msg">
                        {" "}
                        {translation.validations.required_field}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                ) : null
              ) : null}

              {/* brand Arabic*/}
              {language === "ar" ? (
                selectedSubcategory && selectedSubcategory?.brand_ar?.length ? (
                  <FormControl fullWidth>
                    <Select
                      className="select_add"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={brand}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleBrandChange}
                    >
                      <MenuItem value="" disabled>
                        {translation.add_product.select_brand}
                      </MenuItem>
                      {selectedSubcategory?.brand_ar?.length
                        ? selectedSubcategory?.brand_ar?.map(
                            (item: string, i: number) => (
                              <MenuItem key={i} value={item}>
                                {item || ""}
                              </MenuItem>
                            )
                          )
                        : null}
                    </Select>
                    {error && !brand ? (
                      <h6 className="err_msg">
                        {" "}
                        {translation.validations.required_field}
                      </h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                ) : null
              ) : null}

              {/* Dynamic Fields english */}
              {language === "en" &&
              selectedSubcategory?.brand?.length &&
              selectedSubcategory?.dynamicFields?.length
                ? selectedSubcategory?.dynamicFields
                    .slice(1)
                    .map((field, index) => {
                      const fieldName = Object.keys(field)[0];
                      const fieldType = Object.values(field)[0];

                      return (
                        <div key={index}>
                          <InputField
                            key={index}
                            placeholder={fieldName}
                            name={fieldName}
                            id={fieldName}
                            value={formik.values[fieldName]}
                            inputProps={{ maxLength: 8 }}
                            onChange={(val) => {
                              if (
                                val.target.value === " " ||
                                val.target.value === "."
                              ) {
                              } else {
                                formik.handleChange(val);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            // Add validation schema for dynamic fields if needed
                            helperText={
                              formik.touched[fieldName] &&
                              formik.errors[fieldName] && (
                                <div>{formik.errors[fieldName]}</div>
                              )
                            }
                          />
                        </div>
                      );
                    })
                : null}
              {language === "en" &&
              selectedSubcategory?.brand?.length === 0 &&
              selectedSubcategory?.dynamicFields?.length
                ? selectedSubcategory?.dynamicFields.map((field, index) => {
                    const fieldName = Object.keys(field)[0];
                    return (
                      <div key={index}>
                        <InputField
                          key={index}
                          placeholder={fieldName}
                          name={fieldName}
                          id={fieldName}
                          inputProps={{ maxLength: 8 }}
                          value={formik.values[fieldName]}
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else {
                              formik.handleChange(val);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          helperText={
                            formik.touched[fieldName] &&
                            formik.errors[fieldName] && (
                              <div>{formik.errors[fieldName]}</div>
                            )
                          }
                        />
                      </div>
                    );
                  })
                : null}
              {language === "ar" &&
              selectedSubcategory?.dynamicFields_ar?.length
                ? selectedSubcategory?.dynamicFields_ar
                    // .slice(1)
                    .map((field, index) => {
                      const fieldName = Object.keys(field)[0];
                      return (
                        <div key={index}>
                          <InputField
                            key={index}
                            placeholder={fieldName}
                            name={fieldName}
                            id={fieldName}
                            inputProps={{ maxLength: 8 }}
                            value={formik.values[fieldName]}
                            onChange={(val) => {
                              if (
                                val.target.value === " " ||
                                val.target.value === "."
                              ) {
                              } else {
                                formik.handleChange(val);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            helperText={
                              formik.touched[fieldName] &&
                              formik.errors[fieldName] && (
                                <div>{formik.errors[fieldName]}</div>
                              )
                            }
                          />
                        </div>
                      );
                    })
                : null}

              <InputField
                placeholder={translation.add_product.product_name}
                name="productName"
                id="productName"
                inputProps={{ maxLength: 40 }}
                value={formik.values.productName}
                onChange={(val) => {
                  const newValue = val.target.value;
                  const phonePattern = /[0-9]{6}/;
                  if (newValue.includes("@") || phonePattern.test(newValue)) {
                    errorToast(
                      "You cannot enter an email or phone number here."
                    );
                    return;
                  }

                  formik.handleChange(val);
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.productName && formik.errors.productName
                }
              />

              <InputField
                placeholder={translation.add_product.price}
                name="price"
                id="price"
                value={formik.values.price}
                inputProps={{ maxLength: 10 }}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const regex = /^\d*\.?\d{0,2}$/;
                  if (regex.test(inputValue)) {
                    formik.handleChange(event);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.price && formik.errors.price}
              />

              <InputField
                placeholder={translation.Globals.minimum_quantity}
                name="quantity"
                id="quantity"
                value={formik.values.quantity}
                inputProps={{ maxLength: 40 }}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.quantity && formik.errors.quantity}
              />

              <TextField
                type="text"
                className="text_field text_area"
                hiddenLabel
                variant="outlined"
                placeholder={translation.add_product.descriprtion}
                multiline
                rows={4}
                fullWidth
                name="description"
                id="description"
                value={formik.values.description}
                onChange={handleChangeDescription}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />

              <div className="MakeOff">
                <h3>{translation.product.make_offer || ""}</h3>
                <Switch
                  {...label}
                  checked={makeOffer}
                  size="small"
                  onChange={() => setMakeOffer(!makeOffer)}
                />
              </div>
              <div className="btn">
                <Button
                  value={
                    !prodId
                      ? translation.add_product.publish_post
                      : translation.add_product.update_post
                  }
                  onClick={() => setError(true)}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpen(false)} />
            </div>
            <h2>
              {translation.Globals.bill_total || ""} :{" "}
              {translation.Globals.aed || ""} {boosterPrice || ""}
            </h2>
            <h4 style={{ padding: "10px 0" }}>
              {translation.Globals.online_pay || ""}
            </h4>
            <p style={{ color: "gray", fontSize: "14px" }}>
              {translation.Globals.first_pay || ""}
            </p>
            <Box>
              <ul className="cards_sec">
                {cards?.length ? (
                  cards?.map((item) => {
                    return (
                      <>
                        <li
                          className={
                            selectedCard === item?.id
                              ? "mn_crds add_dtls"
                              : "mn_crds"
                          }
                          onClick={() => {
                            setSelectedCard(item?.id);
                          }}
                        >
                          <div className="crd_dtls ">
                            {item?.brand ? (
                              <figure>
                                <img src={cardImage(item?.brand)} alt="img" />
                              </figure>
                            ) : (
                              <figure>
                                <img
                                  src={"/static/images/visa.png"}
                                  alt="img"
                                />
                              </figure>
                            )}
                            <div className="dtl_mn">
                              <h4>•••• •••• •••• {item?.last4 || ""}</h4>
                              <p>
                                {translation.Globals.expiry_date} -{" "}
                                {item?.exp_month || ""}/{item?.exp_year || ""}
                              </p>
                            </div>
                          </div>
                          <figure className="delete_dv">
                            {language === "ar" ? (
                              <ArrowBackIosIcon />
                            ) : (
                              <ArrowForwardIosIcon />
                            )}
                          </figure>
                        </li>
                      </>
                    );
                  })
                ) : (
                  <div
                    className="noData paymnt_nodata_add"
                    style={{ padding: "20px 0 20px 0" }}
                  >
                    <figure>
                      <img src="/static/images/no_card.png" alt="no_card" />
                    </figure>
                    <h3>{translation.profile.no_cards}</h3>
                  </div>
                )}
                {selectedCard ? (
                  <Button
                    value={translation.Globals.proceed}
                    onClick={() => buyBooster(selectedValue)}
                  />
                ) : null}
              </ul>

              <div
                className="add_crd"
                onClick={() => {
                  setOpen(false);
                  setOpenAddCard(true);
                }}
              >
                <figure
                  style={{
                    marginRight: language === "ar" ? 0 : "10px",
                    marginLeft: language === "ar" ? "10px" : "0px",
                  }}
                >
                  <img src="/static/images/add_card.svg" alt="add_card" />
                </figure>
                <div className="add_details">
                  <h4>{translation.profile.new_card}</h4>
                  <p>{translation.profile.pay_via}</p>
                  <div className="paymnt_cards">
                    <figure
                      style={{
                        marginRight: language === "ar" ? 0 : "10px",
                        marginLeft: language === "ar" ? "10px" : "0px",
                      }}
                    >
                      <img src="/static/images/visa.svg" alt="visa" />
                    </figure>
                    <figure
                      style={{
                        marginRight: language === "ar" ? 0 : "10px",
                        marginLeft: language === "ar" ? "10px" : "0px",
                      }}
                    >
                      <img
                        src="/static/images/master-card.svg"
                        alt="master-card"
                      />
                    </figure>
                    <figure
                      style={{
                        marginRight: language === "ar" ? 0 : "10px",
                        marginLeft: language === "ar" ? "10px" : "0px",
                      }}
                    >
                      <img src="/static/images/discover.svg" alt="discover" />
                    </figure>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openAddCard}
        onClose={() => setOpenAddCard(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <div className="payment_modl">
            <div className="cross">
              <CloseIcon onClick={() => setOpenAddCard(false)} />
            </div>
            <h2>{translation.profile.new_card}</h2>
            <p>{translation.profile.enter_details}</p>
            <form onSubmit={paymentFormik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={paymentFormik.values.cardHolderName}
                    id="standard-basic"
                    placeholder={translation.profile.holder_name}
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    name="cardHolderName"
                    inputProps={{ maxLength: 20 }}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        paymentFormik.handleChange(val);
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.cardHolderName &&
                      paymentFormik.errors.cardHolderName
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="standard-basic"
                    placeholder={translation.profile.card_number}
                    variant="standard"
                    className="paymnt_modl_txtfld"
                    name="cardNumber"
                    inputProps={{ maxLength: 16 }}
                    value={paymentFormik.values.cardNumber}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isNumber(val.target.value)) {
                        paymentFormik.handleChange(val);
                      }
                    }}
                    onBlur={paymentFormik.handleBlur}
                    helperText={
                      paymentFormik.touched.cardNumber &&
                      paymentFormik.errors.cardNumber
                    }
                  />
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: "16px",
                    paddingTop: "16px",
                  }}
                >
                  <Grid item xs={5}>
                    <TextField
                      className="paymnt_modl_txtfld"
                      variant="standard"
                      placeholder="MM/YYYY"
                      inputProps={{ maxLength: 7 }}
                      value={paymentFormik.values.expiryDate}
                      name="expiryDate"
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue
                          .replace(/\D/g, "")
                          .replace(/(\d{2})(\d{0,4})/, "$1/$2");

                        paymentFormik.handleChange({
                          target: {
                            name: "expiryDate",
                            value: formattedValue,
                          },
                        });
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Backspace") {
                          paymentFormik.handleChange({
                            target: {
                              name: "expiryDate",
                              value: paymentFormik.values.expiryDate.slice(
                                0,
                                -1
                              ),
                            },
                          });
                        }
                      }}
                      onBlur={paymentFormik.handleBlur}
                      helperText={
                        paymentFormik.touched.expiryDate &&
                        paymentFormik.errors.expiryDate
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      id="standard-basic"
                      placeholder={translation.profile.cvv}
                      variant="standard"
                      className="paymnt_modl_txtfld"
                      name="cvv"
                      inputProps={{ maxLength: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <figure className="cvv_img">
                              <img src="/static/images/cvv.svg" alt="cvv" />
                            </figure>
                          </InputAdornment>
                        ),
                      }}
                      value={paymentFormik.values.cvv}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isNumber(val.target.value)) {
                          paymentFormik.handleChange(val);
                        }
                      }}
                      onBlur={paymentFormik.handleBlur}
                      helperText={
                        paymentFormik.touched.cvv && paymentFormik.errors.cvv
                      }
                    />
                  </Grid>
                </Box>

                <Box style={{ padding: "30px 0 20px 16px", width: "100%" }}>
                  <Button value={translation.Globals.save} />
                </Box>
              </Grid>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddProduct;

import React from "react";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { Link } from "react-router-dom";
import useCategory from "../../hooks/useCategory";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 10,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
  ],
};

const Categories = () => {
  const categories = useCategory();
  const language = getFromStorage(STORAGE_KEYS.language);

  return (
    <section className="hm_categories container prodcts_sc ">
      <ul>
        {categories?.length ? (
          <Slider {...settings}>
            {categories?.map((item) => {
              return (
                <li key={item?._id}>
                  <Link
                    to={{
                      pathname: `/products/${item?._id}`,
                      search: `?name_ar=${encodeURIComponent(
                        item?.name_ar || ""
                      )}&name_en=${encodeURIComponent(
                        item?.name || ""
                      )}&subCat=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                    }}
                  >
                    <figure>
                      <img src={item?.image[0] || ""} alt="category" />
                    </figure>
                    <h6>{language === "ar" ? item?.name_ar : item?.name}</h6>
                  </Link>
                </li>
              );
            })}
          </Slider>
        ) : null}
      </ul>
    </section>
  );
};

export default Categories;

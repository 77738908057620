/* eslint-disable jsx-a11y/role-supports-aria-props */
// @ts-ignore
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Skeleton,
} from "@mui/material";

import LanguageIcon from "@mui/icons-material/Language";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import SearchBar from "../../components/SearchBar";
import "../Layout.scss";
import Auth from "../../features/auth/Auth";
import { categoryData, toggleAuthModal } from "../../reducers/generalSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { Colors } from "../../assets";
import useAuth from "../../hooks/useAuth";

import {
  useLazyGetProfileQuery,
  useLogoutApiMutation,
} from "../../service/Auth";

import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../helpers";

import {
  getTempToken,
  setCredentials,
  temporaryToken,
} from "../../reducers/authSlice";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import {
  useGuestLoginMutation,
  useLazyGetCategoriesQuery,
  useLazyGetSearchHistoryQuery,
  useLazyGlobalSearchQuery,
  usePostClearSearchMutation,
  usePostSaveSearchHistoryMutation,
} from "../../service/Home";
import {
  CommonBody,
  ProductSearchData,
  globalSearch,
} from "../../types/General";

import { generateEncryptedKeyBody } from "../../utils/crypto";

import useTranslation from "../../hooks/Translation";
import useCategory from "../../hooks/useCategory";

import { useLanguageContext } from "../../context/LanguageContext";

import SellerModal from "../../components/SellerModal";
import BreadcrumbComponent from "../../components/Breadcrumbs";
import { log } from "console";

type HeaderType = {
  scrollPosition: any;
};

const Header = ({ scrollPosition }: HeaderType) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const resultsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const fcm_token = getFromStorage(STORAGE_KEYS.fcmToken);
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const { language, changeLanguage } = useLanguageContext();

  const user = useAuth();
  const categories = useCategory();
  console.log(categories, "categories");

  const style = generateResponsiveStyle();
  const navigate = useNavigate();
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);

  const dispatch = useAppDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [openSellerModal, setOpenSellerModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [openLogout, setOpenLogout] = useState(false);
  const [getProfile] = useLazyGetProfileQuery();
  const [postLogout, GetLogoutData] = useLogoutApiMutation();
  const [getCategories] = useLazyGetCategoriesQuery();
  const [searchHistoryData, setSearchHistoryData] = useState<
    ProductSearchData[]
  >([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchPage, setSearchPage] = useState(false);

  const [searchProduct, setSearchProduct] = useState<globalSearch[]>([]);

  const [guestLoginMutation] = useGuestLoginMutation();
  const [globalSearchMutation] = useLazyGlobalSearchQuery();
  const [saveSearchHistory] = usePostSaveSearchHistoryMutation();
  const [getSearchHistory] = useLazyGetSearchHistoryQuery();
  const [clearSearchHistory, GetClearSearchHistoryData] =
    usePostClearSearchMutation();
  const [isSearch, setIsSearch] = useState(false);

  const popupRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as EventTarget | null;
    if (
      target &&
      !(target instanceof HTMLElement && target.closest(".product_srch"))
    ) {
      setShowSearch(false);
    }
  };

  useEffect(() => {
    if (showSearch) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSearch]);

  const guestLogin = async () => {
    const fcmToken = getFromStorage(STORAGE_KEYS.fcmToken);

    const data = {
      deviceType: "WEB",
      deviceToken: fcmToken || "1234",
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await guestLoginMutation(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          temporaryToken({
            tempToken: response?.data?.token || null,
          })
        );
        setToStorage(STORAGE_KEYS.tempToken, response?.data?.token);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getSearchHistoryData = async () => {
    try {
      const response = await getSearchHistory({}).unwrap();
      if (response?.statusCode === 200) {
        setShowSearch(!showSearch);
        setSearchHistoryData(response?.data?.productSearch || []);
      } else {
        setSearchHistoryData([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const clearSearchHistoryData = async () => {
    try {
      const response = await clearSearchHistory({}).unwrap();
      if (response?.statusCode === 200) {
        getSearchHistoryData();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const closeModal = () => {
    setOpenLogout(false);
  };

  const getAllCategories = async () => {
    setLoading(true);
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          categoryData({
            categories: response?.data?.category,
          })
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleLogout = async () => {
    try {
      const response = await postLogout({}).unwrap();
      if (response?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: null,
            user: null,
          })
        );
        dispatch(
          temporaryToken({
            tempToken: null,
          })
        );
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.userData);
        removeFromStorage(STORAGE_KEYS.tempToken);

        closeModal();
        navigate("/");
        window.location.reload();
      }
    } catch (error: any) {
      if (error?.data?.message) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);

    setLoading(true);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        setLoading(false);
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
      return result;
    } catch (error) {
      setLoading(false);
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: user || null,
        })
      );
      if (!user) {
        getUserDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const globalSearch = async () => {
    setSearchLoading(true);
    try {
      const res = await globalSearchMutation({
        search: debouncedSearchTerm.trim(),
      }).unwrap();

      if (res?.statusCode === 200) {
        setSearchLoading(false);
        if (!searchPage) {
          setShowSearch(true);
        }

        setSearchProduct(res?.data?.ItemSearch?.productSearch || null);
      }
    } catch (error) {
      setSearchLoading(true);
      console.log(error);
    }
  };
  const handleSaveSearchHistory = async (id: string) => {
    let data = {
      type: 1,
      search: searchTerm,
      objectId: id,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await saveSearchHistory(encryptedBody).unwrap();

      if (res?.statusCode === 200) {
        setDebouncedSearchTerm("");
        setSearchTerm("");
        setSearchProduct([]);
        navigate(`/searched-products/${debouncedSearchTerm.trim()}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!token && !tempToken) {
      guestLogin();
    }
  }, [fcm_token]);

  useEffect(() => {
    if (token || tempToken) {
      if (isSearch) {
        globalSearch();
      }
    }
  }, [debouncedSearchTerm, tempToken]);

  useEffect(() => {
    if (token || tempToken) {
      if (!categories?.length) {
        getAllCategories();
      }
    }
  }, [tempToken]);

  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    setAnchorEl1(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const change = () => {
    const body = document.body; // Use document.body directly to get the body element
    if (body) {
      const language = getFromStorage(STORAGE_KEYS.language);
      const isArabic = language === "ar";
      // Remove the opposite class before adding the new one
      body.classList.remove(isArabic ? "english_lang" : "arabic_lang");
      body.classList.add(isArabic ? "arabic_lang" : "english_lang");
    }
  };

  useEffect(() => {
    change();
  }, [getLang]);

  useEffect(() => {
    if (!getLang) {
      setToStorage(STORAGE_KEYS.language, "en");
    }
  }, []);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault(); // Prevent page scrolling
      setSelectedIndex((prevIndex) =>
        prevIndex < searchProduct?.length - 1 ? prevIndex + 1 : prevIndex
      );
      scrollSelectedItemIntoView("ArrowDown");
    } else if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent page scrolling
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );

      scrollSelectedItemIntoView("ArrowUp");
      if (selectedIndex == 0) {
        inputRef?.current?.focus();
      }
    } else if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      const selectedProduct = searchProduct[selectedIndex];
      if (selectedProduct) {
        handleSaveSearchHistory(selectedProduct._id);
        navigate(`/product-detail/${selectedProduct._id}`);
        setShowSearch(false);
      }
    }
  };

  const handleHistoryEvents = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < searchHistoryData.length - 1 ? prevIndex + 1 : prevIndex
      );
      scrollSelectedItemIntoView("ArrowDown");
    } else if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent page scrolling
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );

      scrollSelectedItemIntoView("ArrowUp");
    } else if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      const selectedProduct = searchHistoryData[selectedIndex];
      if (selectedProduct) {
        handleSaveSearchHistory(selectedProduct?.objectId);
        navigate(`/product-detail/${selectedProduct?.objectId}`);
        setShowSearch(false);
      }
    }
  };

  const scrollSelectedItemIntoView = (str: string) => {
    if (resultsRef.current) {
      const selectedElement = resultsRef.current.querySelector(".selected");
      if (selectedElement) {
        const containerRect = resultsRef.current.getBoundingClientRect();

        const elementRect = selectedElement.getBoundingClientRect();

        const isAbove = elementRect.top >= containerRect.top;
        const isBelow = elementRect.bottom <= containerRect.bottom;

        if (isAbove && str === "ArrowDown") {
          resultsRef.current.scrollTop -= containerRect.top - elementRect.top;
        } else if (isBelow && str === "ArrowUp") {
          resultsRef.current.scrollTop +=
            elementRect.bottom - containerRect.bottom;
        }
      }
    }
  };

  const handleStartSelling = async () => {
    const response = (await getUserDetails()) as any;

    if (response?.data?.productsAdded && !response?.data?.sellFormExists) {
      errorToast("Please fill the seller form first");
      // setOpenSellerModal(true);
      navigate("/sell");
    } else if (response?.data?.sellerForm?.status === 1) {
      navigate("/profile", { state: "seller_details" });
      errorToast(translation.Globals.not_approved);
    } else if (response?.data?.sellerForm?.status === 2) {
      navigate("/choose-category");
    } else {
      errorToast(
        "Your business details have been rejected by admin. Please enter the details again to become a seller"
      );
      // setOpenSellerModal(true);
      navigate("/sell");
    }
  };

  const handleLogin = () => {
    if (!user) {
      dispatch(
        toggleAuthModal({
          isAuthModalVisible: true,
          ModalType: "login",
        })
      );
    } else if (user && !user?.isProfileComplete) {
      dispatch(
        toggleAuthModal({
          isAuthModalVisible: true,
          ModalType: "profileSetup",
        })
      );
    } else if (user?.productsAdded && !user?.sellFormExists) {
      errorToast("Please fill the seller form first");
      // setOpenSellerModal(true);
      navigate("/sell");
    } else if (user?.sellerForm?.status === 1) {
      navigate("/profile", { state: "seller_details" });
      errorToast(translation.Globals.not_approved);
    } else if (user?.sellerForm?.status === 2) {
      navigate("/choose-category");
    } else if (user?.sellerForm?.status === 3) {
      navigate("/profile", { state: "seller_details" });
      errorToast(
        "Your business details have been rejected by admin. Please enter the details again to become a seller"
      );
    }
  };
  const handleSignUp = () => {
    dispatch(
      toggleAuthModal({
        isAuthModalVisible: true,
        ModalType: "signUp",
      })
    );
  };

  return (
    <>
      <header className="hdr">
        <Loader
          isLoad={
            GetLogoutData?.isLoading || GetClearSearchHistoryData?.isLoading
          }
        />

        <div className="hdr_mn">
          <div className="container hdr_top">
            <div className="hdr_lft">
              <div className="logo_dv">
                <figure
                  className="logo_img hdr_logo"
                  onClick={() => navigate("/")}
                >
                  <LazyLoadImage
                    src="/static/images/krafo_logo.png"
                    alt="logo"
                    effect="blur"
                    height="100%"
                    width="100%"
                    scrollPosition={scrollPosition}
                  />
                </figure>
                <h6>{translation.Globals.wholesale || ""}</h6>
              </div>
            </div>
            <div className="hdr_srch">
              <SearchBar
                onCross={() => setSearchTerm("")}
                placeholder={translation.header.search}
                value={searchTerm}
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                inputRef={inputRef}
                onChange={(val: any) => {
                  setIsSearch(true);
                  setSearchTerm(val.target.value);
                }}
                handleKeyDown={(event: any) => {
                  if (event.key === "ArrowDown") {
                    event?.preventDefault();
                    resultsRef?.current?.focus();
                  } else if (event.key === "Enter" && searchTerm !== "") {
                    setSearchPage(true);
                    navigate(`/searched-products/${searchTerm.trim()}`);
                    setShowSearch(false);
                  }
                }}
                onClick={() => {
                  getSearchHistoryData();
                }}
              />

              {showSearch &&
              (searchProduct?.length ||
                debouncedSearchTerm?.length ||
                searchHistoryData?.length) ? (
                <div
                  className="product_srch"
                  ref={resultsRef}
                  tabIndex={0} // Make the div focusable
                  onKeyDown={
                    searchProduct?.length ? handleKeyDown : handleHistoryEvents
                  } // Listen for keyboard events
                  onBlur={() => resultsRef.current?.focus()} // Refocus when losing focus
                >
                  <div className="srch_hdng">
                    {!debouncedSearchTerm?.length ? (
                      <h4>{translation.header.recently_searched}</h4>
                    ) : null}
                    {!debouncedSearchTerm?.length ? (
                      <p
                        className="clr_btn"
                        onClick={() => clearSearchHistoryData()}
                      >
                        {translation.Globals.clear}
                      </p>
                    ) : null}
                  </div>
                  {debouncedSearchTerm?.length ? (
                    searchProduct?.length ? (
                      searchProduct?.map((item, index) => {
                        return (
                          <>
                            <ul key={item?.id}>
                              <li
                                className={
                                  selectedIndex === index
                                    ? "selected main_li"
                                    : "main_li"
                                }
                                // className="main_li"
                                onClick={() => {
                                  handleSaveSearchHistory(item?._id);
                                }}
                              >
                                <div className="li_left">
                                  {item?.images?.length
                                    ? item?.images
                                        ?.slice(0, 1)
                                        ?.map((val: any, i: number) => {
                                          return (
                                            <figure key={i}>
                                              <img src={val} alt="Product" />
                                            </figure>
                                          );
                                        })
                                    : ""}
                                </div>
                                <div className="li_ryt">
                                  <div className="stock_flex">
                                    <h3>{item?.name || ""}</h3>
                                  </div>
                                  <p>{item?.categoryId?.name || ""}</p>
                                </div>
                              </li>
                            </ul>
                          </>
                        );
                      })
                    ) : (
                      <h2 className="no_record">
                        {searchLoading ? null : translation.Globals.no_product}
                      </h2>
                    )
                  ) : searchHistoryData?.length ? (
                    searchHistoryData?.map((item, index) => {
                      return (
                        <>
                          <ul key={item?.additionalInfo?.categoryId?._id}>
                            <li
                              // onKeyDown={handleHistoryEvents}
                              className={
                                selectedIndex === index
                                  ? "selected main_li"
                                  : "main_li"
                              }
                              onClick={() => {
                                navigate(`/product-detail/${item?.objectId}`);
                                setShowSearch(false);
                              }}
                            >
                              <div className="li_left">
                                {item?.additionalInfo?.images?.length
                                  ? item?.additionalInfo?.images
                                      ?.slice(0, 1)
                                      ?.map((val: any, i: number) => {
                                        return (
                                          <figure key={i}>
                                            <img src={val} alt="Product" />
                                          </figure>
                                        );
                                      })
                                  : ""}
                              </div>
                              <div className="li_ryt">
                                <div className="stock_flex">
                                  <h3>{item?.additionalInfo?.name || ""}</h3>
                                </div>
                                <p>
                                  {item?.additionalInfo?.categoryId?.name || ""}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </>
                      );
                    })
                  ) : (
                    <h2 className="no_record">
                      {" "}
                      {translation.Globals.no_product}
                    </h2>
                  )}
                </div>
              ) : null}
            </div>
            <div className="hdr_ryt">
              {user ? (
                <Box className="users_dv">
                  <IconButton
                    className="topbar_user_btn"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <LazyLoadImage
                      className={user?.image ? "img_style" : "img_style2"}
                      src={user?.image || "/static/images/empty_user.png"}
                      alt="user"
                      effect="blur"
                      height="100%"
                      width="100%"
                      scrollPosition={scrollPosition}
                    />

                    <KeyboardArrowDownIcon />
                  </IconButton>
                  <Menu
                    className="userDropdown_menu"
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 10,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{
                      horizontal: "right",
                      vertical: "top",
                    }}
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                  >
                    <MenuItem
                      style={{ color: Colors.secondary }}
                      value="Profile"
                      onClick={() => navigate("/profile")}
                    >
                      {translation.Globals.profile}
                    </MenuItem>

                    <MenuItem
                      style={{ color: Colors.secondary }}
                      value="Chat"
                      onClick={() => navigate("/chat")}
                    >
                      {translation.Globals.chat}
                    </MenuItem>
                    <MenuItem
                      style={{ color: Colors.secondary }}
                      value="wishlist"
                      onClick={() => navigate("/wishlist")}
                    >
                      {translation.Globals.wishlist}
                    </MenuItem>
                    <MenuItem
                      style={{ color: Colors.secondary }}
                      value="notifications"
                      onClick={() => navigate("/notifications")}
                    >
                      {translation.Globals.notifications}
                    </MenuItem>
                    {/* {user?.isEmailVerify ? ( */}
                    <MenuItem
                      style={{
                        color: Colors.secondary,
                      }}
                      value="Change Password"
                      onClick={() => {
                        handleClose();
                        dispatch(
                          toggleAuthModal({
                            isAuthModalVisible: true,
                            ModalType: "changePassword",
                          })
                        );
                      }}
                    >
                      {translation.Auth.change_password.change}
                    </MenuItem>
                    {/* // ) : null} */}
                    <MenuItem
                      style={{ color: Colors.secondary }}
                      value="Logout"
                      onClick={() => {
                        handleClose();
                        setOpenLogout(true);
                      }}
                    >
                      {translation.Globals.logout}
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <h4>
                  <span onClick={() => handleLogin()}>
                    {translation.Globals.Login}{" "}
                  </span>
                  /{" "}
                  <span onClick={handleSignUp}>
                    {translation.Globals.register}
                  </span>
                </h4>
              )}

              {language === "ar" ? (
                <div
                  className="lang_dv"
                  style={{
                    marginRight: language === "ar" ? 0 : "20px",
                    marginLeft: language === "ar" ? "20px" : 0,
                  }}
                  onClick={() => {
                    changeLanguage("en");
                    setToStorage(STORAGE_KEYS.language, "en");
                    window.location.reload();
                  }}
                >
                  <LanguageIcon
                    sx={{
                      color: "#fff",
                      fontSize: "25px",
                    }}
                  />
                  <h6>English</h6>
                </div>
              ) : (
                <div
                  className="lang_dv"
                  onClick={() => {
                    changeLanguage("ar");
                    setToStorage(STORAGE_KEYS.language, "ar");
                    window.location.reload();
                  }}
                >
                  <LanguageIcon
                    sx={{
                      color: "#fff",
                      fontSize: "25px",
                    }}
                  />
                  <h6>العربية</h6>
                </div>
              )}

              {user ? (
                user?.sellFormExists || user?.productsAdded ? (
                  <Button
                    type="button"
                    className="hdr_btn desk_btn slr_btn"
                    style={{
                      padding:
                        getLang === "ar" ? "0 8px 0 16px" : "0 16px 0 8px",
                      justifyContent: "flex-start",
                      gap: "5px",
                      lineHeight: 0,
                      textTransform: "capitalize",
                      height: "45px",
                      minWidth: "140px",
                    }}
                    onClick={handleStartSelling}
                  >
                    <figure>
                      <img src="/static/images/add_btn.svg" alt="add_btn" />
                    </figure>
                    {translation.Globals.start_selling}
                  </Button>
                ) : null
              ) : (
                <Button
                  type="button"
                  className="hdr_btn desk_btn slr_btn2"
                  style={{
                    padding: getLang === "ar" ? "0 8px 0 16px" : "0 16px 0 8px",
                    justifyContent: "flex-start",
                    gap: "5px",
                    lineHeight: 0,
                    textTransform: "capitalize",
                    height: "45px",
                  }}
                  // onClick={() => setOpenSellerModal(true)}
                  onClick={() => navigate("/sell")}
                >
                  <figure>
                    <img src="/static/images/add_btn.svg" alt="add_btn" />
                  </figure>
                  {translation.Globals.become_seller}
                </Button>
              )}

              {user ? (
                user?.sellFormExists || user?.productsAdded ? (
                  <Button
                    type="button"
                    className="hdr_btn mob_btn"
                    onClick={handleStartSelling}
                  >
                    <figure>
                      <img src="/static/images/add_btn.svg" alt="add_btn" />
                    </figure>
                  </Button>
                ) : null
              ) : (
                <Button
                  type="button"
                  className="hdr_btn mob_btn"
                  // onClick={() => setOpenSellerModal(true)}
                  onClick={() => navigate("/sell")}
                >
                  <figure>
                    <img src="/static/images/add_btn.svg" alt="add_btn" />
                  </figure>
                </Button>
              )}
            </div>
          </div>
        </div>
        <div style={{ boxShadow: "0 2px 14px 1px rgba(0, 0, 0, 0.1)" }}>
          <div className="mn_hdr_btm container">
            <div className="btm_hd">
              <div className="srch_hdr">
                <SearchBar
                  onCross={() => setSearchTerm("")}
                  placeholder={translation.header.search}
                  value={searchTerm}
                  inputRef={inputRef}
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    setIsSearch(true);
                    setSearchTerm(val.target.value);
                  }}
                  onClick={() => {
                    getSearchHistoryData();
                  }}
                />

                {showSearch &&
                (searchProduct?.length ||
                  debouncedSearchTerm?.length ||
                  searchHistoryData?.length) ? (
                  <div className="product_srch" ref={popupRef}>
                    <div className="srch_hdng">
                      {!debouncedSearchTerm?.length ? (
                        <h4>{translation.header.recently_searched}</h4>
                      ) : null}
                      {!debouncedSearchTerm?.length ? (
                        <p
                          className="clr_btn"
                          onClick={() => clearSearchHistoryData()}
                        >
                          {translation.Globals.clear}
                        </p>
                      ) : null}
                    </div>
                    {debouncedSearchTerm?.length ? (
                      searchProduct?.length ? (
                        searchProduct?.map((item) => {
                          return (
                            <>
                              <ul key={item?.id}>
                                <li
                                  className="main_li"
                                  onClick={() => {
                                    handleSaveSearchHistory(item?._id);
                                  }}
                                >
                                  <div className="li_left">
                                    {item?.images?.length
                                      ? item?.images
                                          ?.slice(0, 1)
                                          ?.map((val: any, i: number) => {
                                            return (
                                              <figure key={i}>
                                                <img src={val} alt="Product" />
                                              </figure>
                                            );
                                          })
                                      : ""}
                                  </div>
                                  <div className="li_ryt">
                                    <div className="stock_flex">
                                      <h3>{item?.name || ""}</h3>
                                    </div>
                                    <p>{item?.categoryId?.name || ""}</p>
                                  </div>
                                </li>
                              </ul>
                            </>
                          );
                        })
                      ) : (
                        <h2 className="no_record">
                          {" "}
                          {translation.Globals.no_product}
                        </h2>
                      )
                    ) : searchHistoryData?.length ? (
                      searchHistoryData?.map((item) => {
                        return (
                          <>
                            <ul key={item?.additionalInfo?.categoryId?._id}>
                              <li
                                className="main_li"
                                onClick={() => {
                                  navigate(`/product-detail/${item?.objectId}`);
                                  setShowSearch(false);
                                }}
                              >
                                <div className="li_left">
                                  {item?.additionalInfo?.images?.length
                                    ? item?.additionalInfo?.images
                                        ?.slice(0, 1)
                                        ?.map((val: any, i: number) => {
                                          return (
                                            <figure key={i}>
                                              <img src={val} alt="Product" />
                                            </figure>
                                          );
                                        })
                                    : ""}
                                </div>
                                <div className="li_ryt">
                                  <div className="stock_flex">
                                    <h3>{item?.additionalInfo?.name || ""}</h3>
                                  </div>
                                  <p>
                                    {item?.additionalInfo?.categoryId?.name ||
                                      ""}
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </>
                        );
                      })
                    ) : (
                      <h2 className="no_record">
                        {translation.Globals.no_product}
                      </h2>
                    )}
                  </div>
                ) : null}
              </div>
              <ul className=" hdr_btm">
                <li
                  className="notification_btn"
                  aria-controls={open1 ? "basic-menu1" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? "true" : undefined}
                  onClick={handleClick1}
                >
                  <h2>
                    {translation.header.all_categories}{" "}
                    <KeyboardArrowDownIcon />
                  </h2>
                </li>
                {loading
                  ? Array.from({ length: 7 }, (_, index) => (
                      <Skeleton
                        key={index}
                        variant="text"
                        style={{ fontSize: "1rem", width: "100px" }}
                      />
                    ))
                  : categories?.length
                  ? categories
                      ?.filter((ele) => ele?.isFeatured)
                      ?.map((item, i) => {
                        return (
                          <li key={item?._id}>
                            <Link
                              to={{
                                pathname: `/products/${item?._id}`,
                                search: `?name_ar=${encodeURIComponent(
                                  item?.name_ar || ""
                                )}&name_en=${encodeURIComponent(
                                  item?.name || ""
                                )}&subCat=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                              }}
                            >
                              <h4>
                                {getLang === "ar"
                                  ? item?.name_ar || ""
                                  : item?.name || ""}
                              </h4>
                            </Link>
                          </li>
                        );
                      })
                  : null}
              </ul>
            </div>
            <div
              className="ssnloffr_hdr"
              onClick={() => navigate("/seasonal-offers")}
            >
              <p>{translation.Globals.seasonal_offers || ""}</p>
            </div>
          </div>
        </div>
        <Auth />

        <Menu
          className="notiDropdown_menu"
          id="basic-menu1"
          anchorEl={anchorEl1}
          open={open1}
          onClose={handleClose1}
          MenuListProps={{
            "aria-labelledby": "basic-button1",
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              top: 110,
              left: 97,
              borderRadius: "0px 0px 20px 20px",

              width: "90%",
              "& .MuiAvatar-root": {},
            },
          }}
        >
          <ul className="ul_details">
            {categories?.length
              ? categories?.map((item, i) => {
                  return (
                    <li key={item?._id}>
                      <Link
                        to={{
                          pathname: `/products/${item?._id}`,
                          search: `?name_ar=${encodeURIComponent(
                            item?.name_ar || ""
                          )}&name_en=${encodeURIComponent(
                            item?.name || ""
                          )}&subCat=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                        }}
                      >
                        <h3
                          onClick={() => {
                            setAnchorEl1(null);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {getLang === "ar"
                            ? item?.name_ar || ""
                            : item?.name || ""}
                        </h3>
                      </Link>
                      <div className="sub_cat">
                        {item?.subcategories?.length
                          ? item?.subcategories?.map((val) => {
                              return (
                                <Link
                                  key={i}
                                  to={{
                                    pathname: `/products/${val?.parent}`,
                                    search: `?name_ar=${encodeURIComponent(
                                      item?.name_ar || ""
                                    )}&name_en=${encodeURIComponent(
                                      item?.name || ""
                                    )}&subCat=${
                                      val?._id
                                    }&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                                  }}
                                  state={{
                                    selectedSubcategory: val || "",
                                  }}
                                >
                                  {" "}
                                  <p
                                    onClick={() => {
                                      setAnchorEl1(null);
                                    }}
                                  >
                                    {getLang === "ar"
                                      ? val?.name_ar
                                      : val?.name}
                                  </p>
                                </Link>
                              );
                            })
                          : null}
                      </div>
                    </li>
                  );
                })
              : null}
          </ul>
        </Menu>
        <Modal
          open={openLogout}
          onClose={closeModal}
          hideBackdrop
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="AuthWrap">
              <div className="cross ">
                <CloseIcon onClick={() => setOpenLogout(false)} />
              </div>
              <h2
                style={{
                  textAlign: "center",
                  marginTop: "-20px",
                  fontSize: "22px",
                }}
              >
                {translation.header.sure_to_logout}
              </h2>
              <div className="logut_flx">
                <Button
                  className="yesBtn"
                  sx={{
                    color: "#252525",
                    border: "1px solid #7dbfdd",
                  }}
                  onClick={() => {
                    setOpenLogout(false);
                    handleLogout();
                  }}
                >
                  {translation.header.yes}
                </Button>

                <Button
                  sx={{
                    color: "#252525",
                    border: "1px solid #7dbfdd",
                  }}
                  onClick={() => setOpenLogout(false)}
                >
                  {translation.header.no}
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
        <BreadcrumbComponent />
        <SellerModal
          openSellerModal={openSellerModal}
          setOpenSellerModal={setOpenSellerModal}
        />
      </header>
    </>
  );
};

export default trackWindowScroll(Header);

import React, { useEffect, useState } from "react";
import { BlogCardSkeleton, Button, Pagination } from "../../components";
import Layout from "../../layout";
import "./TopManufacturers.scss";
import { END_POINTS, WORDPRESS_URL } from "../../helpers";
import {
  WORDPRESS_PASSWORD,
  WORDPRESS_USERNAME,
} from "../../helpers/constants/urls";
import useTranslation from "../../hooks/Translation";
import { Skeleton } from "@mui/material";

const TopManufacturers = () => {
  const translation = useTranslation() as any;
  const [loading, setLoading] = useState(false);

  const [topManufacturers, setTopManufacturers] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);

  let totalPages = Math.ceil(count / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getTopManufacturers = async () => {
    const url = `${WORDPRESS_URL}${
      END_POINTS.topManufacturers
    }?page=${page}&per_page=${12}`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${WORDPRESS_USERNAME}:${WORDPRESS_PASSWORD}`
          )}`,
        },
      });
      console.log("Response received: ", response);
      if (response?.ok) {
        const responseData = await response.json();
        if (responseData?.status === 200) {
          setTopManufacturers(responseData?.data);
          setCount(responseData?.total_posts);
          setLoading(false);
        }
      } else {
        setTopManufacturers([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    getTopManufacturers();
  }, [page]);

  return (
    <Layout>
      <>
        {loading ? (
          <section className="blog_cat container">
            <div className="blg_hd">
              <h2>
                {" "}
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "2rem" }}
                  width={"250px"}
                  animation="wave"
                />
              </h2>
            </div>
            <div className="blog_data">
              {Array.from({ length: 6 }, (_, index) => (
                <BlogCardSkeleton key={index} />
              ))}
            </div>
          </section>
        ) : (
          <section className="tp_mnfctr container">
            <div className="mnfctr_hd">
              <h2>{translation.Globals.top_manufacturers || ""}</h2>
            </div>
            <div className="manu_flex">
              {topManufacturers?.length
                ? topManufacturers?.map((item: any, i: number) => {
                    return (
                      <div className="manufacture_single" key={i}>
                        <figure>
                          <img src={item?.image} alt="" />
                        </figure>
                        <div className="inner_content">
                          <h5
                            dangerouslySetInnerHTML={{
                              __html: item?.heading || "",
                            }}
                          />

                          <Button
                            value={item?.button_text || ""}
                            onClick={() => {
                              if (item?.url) {
                                window.open(
                                  item?.url?.startsWith("http")
                                    ? item?.url
                                    : `https://${item?.url}`
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </section>
        )}
        {!loading ? (
          <div className="vw_prfl_pgntn">
            <Pagination
              module={topManufacturers}
              page={page || 1}
              onPageChange={onPageChange}
              totalPages={totalPages}
              setPage={setPage}
            />
          </div>
        ) : null}
      </>
    </Layout>
  );
};

export default TopManufacturers;

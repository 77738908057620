export const ar = {
  Globals: {
    report_ad: "الإبلاغ عن هذا الإعلان",
    Login: "تسجيل الدخول",
    Continue: "إستمرار",
    update_detail: "تحديث التفاصيل",
    sign_in: "تسجيل الدخول",
    sign_up: "إنشاء حساب",
    email: "البريد الإلكتروني",
    phone_number: "رقم الهاتف",
    next: "التالي",
    full_name: "الاسم الكامل",
    address: "العنوان",
    submit: "تقديم",
    clear: "محو",
    no_product: "لم يتم العثور على منتجات",
    profile: "الملف الشخصي",
    chat: "محادثة",
    wishlist: "المفضلة",
    notifications: "إشعارات ",
    logout: "تسجيل الخروج",
    categories: "الفئات ",
    see_all: "شاهد الكل",
    explore_more: "استكشف المزيد ",
    recent_products: "شوهدت مؤخرا",
    featured_products: "المنتجات المميزة",
    recommended_products: "المنتجات الموصى بها",
    download_krafo: "حمل تطبيق كرافو",
    buy_sell:
      "اشتر أو بيع أي شيء باستخدام التطبيق على هاتفك المحمول. ازياء ,إللكترونيات, ,والمزيد",
    select: "إختر",
    what_krafo: "ماذا يعني كرافو ",
    no_info: "لم يتم العثور على معلومات",
    get_started: "ابدأ",
    no_faq: "لم يتم العثور على قائمة الأسئلة الشائعة",
    help: "كيف نستطيع مساعدتك؟",
    message: "رسالة",
    contact_now: "اتصل الان ",
    choose_cat: "اختر فئة",
    sub_cat: "فئات فرعية",
    clear_all: "محو الكل",
    brand: "العلامة التجارية",
    sort: "الترتيب حسب",
    price: "السعر",
    apply: "قدم",
    apply_filter: "قدم التصفية",
    new: "جديد",
    popular: "شائع",
    low_high: "السعر من الاسفل للاعلى",
    high_low: "السعر من الاعلى للاسفل ",
    recent: "حديث",
    no_recent_notifications: "لم يتم العثور على إشعارات حديثة",
    older_notifications: "إشعارات قديمه",
    no_older_notifications: "لم يتم العثور على إشعارات قديمه",
    clear_notifications: "محو كافة الإشعارات ",
    all: "الكل",
    yes: "نعم",
    no: "لا",
    save: "حفظ",
    back: "العودة للخلف",
    update: "تحديث",
    add: "اضافة",
    location: "الموقع",
    edit_detail: "تعديل التفاصيل",
    aed: "د.إ",
    for: "ل",
    bill_total: "إجمالي الفاتورة",
    online_pay: "المدفوعات عبر الإنترنت",
    first_pay:
      "بعد الدفعة الأولى، سنقوم بحفظ التفاصيل الخاصة بك لاستخدامها في المستقبل.",
    expiry_date: "تاريخ الانتهاء",
    proceed: "يتابع",
    delete_card: "هل أنت متأكد من حذف هذه البطاقة؟",
    agree:
      "أوافق، إذا كنت تريد تلقي كلمة المرور لمرة واحدة (OTP) عبر الرسائل القصيرة/البريد الإلكتروني",
    invalidImg: "نوع الملف غير صالح. يرجى تحميل الصور فقط.",
    we_are: "نحن",
    under_maintenance: "تحت الصيانة ",
    apologies:
      "نعتذر عن الإزعاج، فنحن نجري حاليًا أعمال صيانة لتعزيز تجربتك. سنعود إلى الإنترنت قريبًا!",
    refresh: "تحديث هذه الصفحة",
    accepted: "قبلت",
    pending: "قيد الانتظار",
    rejected: "مرفوض",
    wholesale: "بالجملة",
    week: "أسبوع",
    weeks: "أسابيع",
    months: "شهور",
    year: "سنة",
    product_page: "انتقل إلى صفحة المنتج",
    related_products: "منتجات ذات الصله",
    become_seller: "كن بائعآ",
    business_profile: "تفاصيل الأعمال",
    business_name: "الاسم التجاري",
    mobile_no: "رقم الهاتف المحمول",
    business_landline: "الخط الأرضي للأعمال",
    business_email: "بريد العمل",
    license_no: "رقم الرخصة",
    license_no_optional: "رقم الترخيص (اختياري)",
    business_landline_optional: "الخط الأرضي للأعمال (اختياري)",
    start_selling: "إبدأ البيع",
    not_approved: "لم تتم الموافقة على تفاصيل عملك من قبل المشرف حتى الآن",
    status: "الحالة",
    details_added:
      "تمت إضافة تفاصيل العمل بنجاح. الرجاء الانتظار لموافقة المشرف",
    new_arrivals: "تم إضافة حديثآ",
    products: "المنتجات",
    in_focus: "التركيز عليها",
    mostly_search: "الاكثر بحثأ",
    mostly_view: "الاكثر مشاهدة",
    explore_more_products: "استكشف المزيد من المنتجات",
    official_stores: "المتاجر الرسمية",
    visit_store: "قم بزيارة المتجر",
    user_details: "بيانات المستخدم",
    business_details: "تفاصيل الأعمال",
    explore_products: "استكشف المنتجات",
    verification_porocess:
      "ستستغرق عملية التحقق 24 ساعة. بمجرد التحقق سوف تتلقى رسالة بالبريد الإلكتروني",
    agree_to: "الموافقة على ",
    terms_conditions: "يرجى قراءة وقبول الشروط والأحكام",
    accept_terms: "يرجى قراءة وقبول الشروط والأحكام",
    register: "تسجيل",
    details: "تفاصيل",

    ////
    blog: "مدونة",
    seasonal_offers: "العروض الموسمية",
    top_offers: "أعلى العروض",
    hot_deals: "العروض الساخنة",
    seller_form: "نموذج البائع",
    weekly_deals: "العروض الأسبوعية",
    top_manufacturers: "كبار المصنعين",
    minimum_quantity: "الحد الأدنى للكمية",
  },
  Auth: {
    Login: {
      enter_details: "يرجاء إدخال بياناتك",
      continue_with: "إستمرار بواسطة",
      or: "او",
      not_member: "لست عضو",
      remember: "تذكيري",
      forgot_pas: "نسيت كلمة السر؟",
      email_id: "البريد الإلكتروني",
      password: "كلمة السر",
    },
    Sign_up: {
      welcome: "اهلآ بالمستخدم",
      confirm_password: "تاكيد كلمة السر",
      sign_up_with: "تسجيل الدخول بواسطة",
      already_member: "عضو بالفعل",
    },
    forgot_password: {
      enter_email: "ادخل بريدك الإلكتروني هنا",
    },
    otp_verification: {
      verification: "التحقق",
      email_code: "لقد قمنا بإرسال رمز التحقق الى بريدك الإلكتروني",
      phone_code: "لقد قمنا للتو بإرسال رمز التحقق عبر ",
      resend: "إعادة ارسال الرمز ",
      code_expire: "ستنتهي صلاحية رمز التحقق خلال ",
    },
    profile_setup: {
      setup: "إعداد الملف الشخصي",
    },
    reset_password: {
      reset: "إعادة تعيين كلمة السر",
      new_password: "كلمة السر الجديدة",
    },
    change_password: {
      change: "تغير كلمة السر",
      old_pass: "كلمة السر القديمة",
      new_pass: "كلمة المرور الجديدة",
    },
  },
  header: {
    select_location: "اختر الموقع",
    search: "البحث عن طريق الهواتف المحمولة والملابس وغيرها",
    recently_searched: "تم البحث موخرآ",
    sell: "بيع",
    all_categories: "كل الفئات",
    sure_to_logout: "هل انت متاكد من تسجيل الخروج ",
    yes: "نعم",
    no: "لا",
    home: "الرئيسية",
  },
  footer: {
    support: "الدعم",
    contact_us: "اتصل بناء ",
    faq: "الاسئلة الشائعة",
    about_us: "من نحن",
    privacy: "سياسة الخصوصية",
    follow_us: "تابعنا",
    subscribe: "إشتراك",
    enter_email: "ادخل الإلكتروني",
    krafo: "كرافو",
    rights_reserved: "جميع الحقوق محفوظة",
    text: "منصة لتجارة الجملة لعرض منتجاتها للعملاء في جميع أنحاء العالم.",
    terms_conditions: "البنود و الظروف",
    consumer_rights: "حقوق المستهلك",
    sell_with_us: "بيع معنا",
  },
  view_profile: {
    product_report: "لماذا تريد الإبلاغ عن هذا المنتج؟",
    share_profile: "شارك الملف الشخصي",
    report: "إبلاغ",
    total_products: "إجمالي المنتجات",
    total_followers: "إجمالي المتابعين",
    total_following: "إجمالي الذين اتابعهم ",
    follow: "متابعة ",
    unfollow: "إلغاء المتابعة",
    my_products: "منتجاتي",
    user_report: "لماذا ترغب في الإبلاغ عن هذا المستخدم؟",
    your_reason: "السبب",
  },
  profile: {
    my_booster: "بلدي الداعم",
    my_listing: "قائمتي",
    manage_payments: "إدراة الدفعات",
    manage_addresses: "إدارة العناوين",
    delete_account: "حذف الحساب",
    sure_delete: "هل أنت متأكد أنك تريد حذف هذا الحساب؟",
    delete_product: "هل أنت متأكد أنك تريد حذف هذا المنتج؟",
    no_cards: "لم يتم العثور على بطاقات",
    new_card: "اضافة بطاقة جديدة",
    pay_via: "حفظ والدفع عبرالبطاقة ",
    enter_details: "ادخل تفاصيل بطاقتك ",
    holder_name: "اسم حامل البطاقة ",
    card_number: "رقم البطاقة",
    cvv: "رمز التحقق من البطاقة",
    save_card: "حفظ هذه البطاقة للدفعات المستقبلية؟",
    add_address: "اضف عنوان جديدآ",
    edit_address: "تعديل العنوان",
    house: "المنزل/الشقة/ رقم المجمع",
    landmark: "علامة مميزة",
    save_As: "احفظ كـ",
    home: "الرئيسية",
    work: "العمل",
    other: "اخر",
    saved_address: "حفظ العناوين",
    default: "إفتراضي",
    edit: "تعديل",
    no_address: "لم يتم العثور على العنوان",
    delete_address: "هل أنت متأكد أنك تريد حذف هذا العنوان؟",
    no_followers: "لا يوجد متابعون",
    no_following: "انت لم تقم بمتابعة أي شخص حتى الآن",
    manage_profile: "إدارة تفاصيل حسابك الشخصي",
    invalid_email: "بريد إلكتروني غير صالح",
    verify: "تحقق",
    invalid_number: "رقم غير صالح",
    plan_name: "اسم الخطة",
    plan_price: "سعر الخطة",
    plan_validity: "صلاحية الخطة",
    expiry_date: "تاريخ الانتهاء",
    description: "وصف",
  },
  chat: {
    buying: "شراء",
    selling: "بيع",
    no_chats: "لم يتم العثور على محادثات",
    go_ahead: "إبداء المحادثه",
    delete_chat: "حذف المحادثه",
    offer_price: "إقتراح سعر",
    decline: "رفض",
    accept: "موافقة",
    type_message: "اكتب رسالة",
  },
  product: {
    send: "يرسل",
    amount: "كمية",
    product_detail: "تفاصيل المنتج",
    posted_on: "تم النشر في ",
    share: "مشاركة ",
    member_since: "عضو منذ ",
    view_profile: "عرض الملف الشخصي",
    more_details: "المزيد من التفاصيل",
    booster: "ملصق إعلاني",
    edit_detail: "تعديل التفاصيل",
    delete_product: "حذف منتج ",
    make_offer: "إقتراح سعر للشراء ",
    description: "الوصف",
  },
  add_product: {
    almost_there: "انت على وشك الوصول ",
    set_price: "ضع أكبر قدر ممكن من التفاصيل والصور، وحدد السعر الصحيح",
    add_pictue: "اضف صور",
    buy_booster: "هل تود شراء ملصق إعلاني",
    select_sub: "اختيار فئه فرعية",
    select_brand: "اختيار العلامة التجارية",
    product_name: "اسم المنتج ",
    price: "سعر",
    location: "الموقع",
    descriprtion: "الوصف",
    publish_post: "نشر المحتوى",
    update_post: "تحديث المنشور",
  },

  validations: {
    required_field: "هذا الحقل مطلوب ",
    correct_phone: "يرجاء ادخال رقم هاتف صحيح",
    email_required: "البريد الإكتروني مطلوب",
    valid_email: "ادخل بريد إللكتروني صالح",
    password_required: "كلمة السر مطلوبة",
    password_validation:
      "يجب أن تحتوي على 8 أحرف أو أكثر، أحرف كبيرة واحدة، أحرف صغيرة واحدة، رقم واحد وحرف خاص واحد",
    password_match: "يجب ان تطابق كلمة السر",
    images_allowed: "يسمح فقط بالصور",
    min_three: "مطلوب 3 احرف على الاقل ",
    min_two: "مطلوب 2 احرف على الاقل",
    max_forty: "الحد الاقصى المسموح به هوا 40 حرفآ",
    min_seven: "مطلوب 7 أحرف على الأقل",
    max_fifteen: "الحد الأقصى المسموح به هو 15 حرفًا",
    old_pass_required: "كلمة السر القديمة مطلوبة",
    new_pass_required: "كملة السر الجديدة مطلوبة",
    new_pass_validation:
      "لا يمكن أن تكون كلمة السر الجديدة مماثلة لكلمة السر القديمة",
    card_holder_name: "يجب ان يحتوي اسم حامل البطاقة على ثلاثه احرف على الاقل ",
    phone_min: "يجب ان يحتوي رقم الهاتف على 6 ارقام على الاقل ",
    phone_max: "يجب ان تحتوي البطاقة  على 16 رقم على الاقل",
    cardNo_val: "يجب أن لا يقل رقم البطاقة عن 16 حرفًا",
    cvv_val: "يجب أن يتكون رمز CVV من 3 أحرف على الأقل",
  },
  toast_messages: {
    setup_complete: "تهانينا! تم اكتمال ملفك الشخصي بنجاح",
    password_updated: "تم تحديث كلمة السر بنجاح ",
    try_again: "يرجى المحاولة مرة اخرى",
    query_sent: "تم ارسال الاستفسار بنجاح",
    notifications_clear: "تم محو الاشعارات بنجاح",
    report_sent: "تم ارسال التقرير بنجاح",
    account_deleted: "تم حذف الحساب بنجاح",
    address_added: "تم أضافة العنوان بنجاح",
    address_updated: "تم تحديث العنوان بنجاح",
    select_default: "الرجاء اختيار العنوان الافتراضي ",
    verify_phone: "الرجاء تاكيد رقم الهاتف اولآ ",
    verify_email: "الرجاء تاكيد البريد الإكتروني اولآ ",
    profile_updated: "تم تحديث الملف الشخصي بنجاح ",
    chat_deleted: "تم حذف المحادثة بنجاح",
    enter_offer: "الرجاء ادخال سعر العرض ",
    image_required: "الصورة مطلوبة",
    product_added: "تم اضافة المنتج بنجاح",
    product_updated: "تم تحديث المنتج بنجاح",
    product_deleted: "تم حذف المنتج بنجاح",
    agree: "يرجى الموافقة على تلقي كلمة المرور لمرة واحدة للمتابعة",
    location_updated: "تم تحديث الموقع بنجاح",
    max6: "لا يمكن تحميل أكثر من 6 صور",
  },
};

import React from "react";
import useTranslation from "../../hooks/Translation";

const DownloadApp = () => {
  const translation = useTranslation() as any;
  return (
    <section className="dwnld_bnr">
      <div className="mn_bnr_dwnld container">
        <div className="dwnld_bnr_lft">
          <div className="dwnld_bnr_lft_1">
            <h2>{translation.Globals.download_krafo}</h2>
            <p>{translation.Globals.buy_sell}</p>
          </div>
          <div className="plystr_btns">
            <a
              href="https://play.google.com/store/apps/developer?id=KRAFO"
              target="_blank"
              rel="noopener noreferrer"
            >
              <figure>
                <img src="/static/images/play_store.png" alt="play_store" />
              </figure>
            </a>
            <a
              href="https://apps.apple.com/us/app/krafo-wholesales-e-commerce/id6476921801"
              target="_blank"
              rel="noopener noreferrer"
            >
              <figure>
                <img src="/static/images/app_store.png" alt="app_store" />
              </figure>
            </a>
          </div>
          <figure className="abs_img">
            <img src="/static/images/bnr_phone.png" alt="bnr_phone" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;

import { ProductBannerData } from "../../types/General";

type props = {
  productBannerData: ProductBannerData[];
};

const AdsSection = ({ productBannerData }: props) => {
  const adsData = productBannerData?.filter((item) => item.type === 2);
  return (
    <>
      {adsData?.length ? (
        <section className="hm_ads container">
          <div className="ads_flex">
            {adsData[0]?.link?.length ? (
              <a
                href={
                  adsData[0]?.link?.startsWith("http")
                    ? adsData[0]?.link
                    : `https://${adsData[0]?.link}`
                }
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) =>
                  adsData[0]?.link?.length ? undefined : e.preventDefault()
                }
              >
                <figure className="adsImage">
                  <img src={adsData[0]?.webImage} alt="banner" />
                </figure>
              </a>
            ) : (
              <span>
                <figure className="adsImage">
                  <img src={adsData[0]?.webImage} alt="banner" />
                </figure>
              </span>
            )}
            {adsData[1]?.link?.length ? (
              <a
                href={
                  adsData[1]?.link?.length
                    ? adsData[1]?.link?.startsWith("http")
                      ? adsData[1]?.link
                      : `https://${adsData[1]?.link}`
                    : undefined
                }
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) =>
                  adsData[1]?.link?.length ? undefined : e.preventDefault()
                }
              >
                <figure className="adsImage">
                  <img src={adsData[1]?.webImage} alt="banner" />
                </figure>
              </a>
            ) : (
              <span>
                <figure className="adsImage">
                  <img src={adsData[1]?.webImage} alt="banner" />
                </figure>
              </span>
            )}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default AdsSection;

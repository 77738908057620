import React from "react";

const WeeklyDeals = ({ weeklyDeals }: any) => {
  return (
    <section className="prodcts_sc container">
      <div className="prdct_hdngs ">
        <h2>{weeklyDeals?.heading || ""}</h2>
      </div>
      <div className="wkly_dls_mn">
        <div
          className="dls_lft"
          style={{
            backgroundImage: `url(${weeklyDeals?.weekly_data?.[0]?.image})`,
          }}
        >
          {" "}
          <a
            href={
              weeklyDeals?.weekly_data?.[0]?.url?.includes("products")
                ? weeklyDeals?.weekly_data?.[0]?.url.replace(
                    "products",
                    "weekly-deals"
                  )
                : weeklyDeals?.weekly_data?.[0]?.url.startsWith("http")
                ? weeklyDeals?.weekly_data?.[0]?.url
                : `https://${weeklyDeals?.weekly_data?.[0]?.url}`
            }
            // href={
            //   weeklyDeals?.weekly_data?.[0]?.url?.startsWith("http")
            //     ? weeklyDeals?.weekly_data?.[0]?.url
            //     : `https://${weeklyDeals?.weekly_data?.[0]?.url}`
            // }
            // target="_blank"
            // rel="noopener noreferrer"
          />
          <h4
            dangerouslySetInnerHTML={{
              __html: weeklyDeals?.weekly_data?.[0]?.title || "",
            }}
          />
        </div>

        {weeklyDeals?.weekly_data?.length >= 2 ? (
          <div className="dls_ryt">
            {weeklyDeals?.weekly_data?.[1]?.image?.length ? (
              <div
                className="dls_ryt_tp"
                style={{
                  backgroundImage: `url(${weeklyDeals?.weekly_data?.[1]?.image})`,
                }}
              >
                <a
                  // href={
                  //   weeklyDeals?.weekly_data?.[1]?.url?.startsWith("http")
                  //     ? weeklyDeals?.weekly_data?.[1]?.url
                  //     : `https://${weeklyDeals?.weekly_data?.[1]?.url}`
                  // }
                  href={
                    weeklyDeals?.weekly_data?.[1]?.url?.includes("products")
                      ? weeklyDeals?.weekly_data?.[1]?.url.replace(
                          "products",
                          "weekly-deals"
                        )
                      : weeklyDeals?.weekly_data?.[1]?.url.startsWith("http")
                      ? weeklyDeals?.weekly_data?.[1]?.url
                      : `https://${weeklyDeals?.weekly_data?.[1]?.url}`
                  }
                  // target="_blank"
                  // rel="noopener noreferrer"
                />
                <h4
                  dangerouslySetInnerHTML={{
                    __html: weeklyDeals?.weekly_data?.[1]?.title || "",
                  }}
                />
              </div>
            ) : null}
            {weeklyDeals?.weekly_data?.length >= 3 ? (
              <div className="dls_ryt_btm">
                {weeklyDeals?.weekly_data?.[2]?.image?.length ? (
                  <div
                    style={{
                      backgroundImage: `url(${weeklyDeals?.weekly_data?.[2]?.image})`,
                    }}
                  >
                    <a
                      href={
                        weeklyDeals?.weekly_data?.[2]?.url?.includes("products")
                          ? weeklyDeals?.weekly_data?.[2]?.url.replace(
                              "products",
                              "weekly-deals"
                            )
                          : weeklyDeals?.weekly_data?.[2]?.url.startsWith(
                              "http"
                            )
                          ? weeklyDeals?.weekly_data?.[2]?.url
                          : `https://${weeklyDeals?.weekly_data?.[2]?.url}`
                      }
                      // href={
                      //   weeklyDeals?.weekly_data?.[2]?.url?.startsWith("http")
                      //     ? weeklyDeals?.weekly_data?.[2]?.url
                      //     : `https://${weeklyDeals?.weekly_data?.[2]?.url}`
                      // }
                      // target="_blank"
                      // rel="noopener noreferrer"
                    />
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: weeklyDeals?.weekly_data?.[2]?.title || "",
                      }}
                    />
                  </div>
                ) : null}
                {weeklyDeals?.weekly_data?.[3]?.image?.length ? (
                  <div
                    style={{
                      backgroundImage: `url(${weeklyDeals?.weekly_data?.[3]?.image})`,
                    }}
                  >
                    <a
                      href={
                        weeklyDeals?.weekly_data?.[3]?.url?.includes("products")
                          ? weeklyDeals?.weekly_data?.[3]?.url.replace(
                              "products",
                              "weekly-deals"
                            )
                          : weeklyDeals?.weekly_data?.[3]?.url.startsWith(
                              "http"
                            )
                          ? weeklyDeals?.weekly_data?.[3]?.url
                          : `https://${weeklyDeals?.weekly_data?.[3]?.url}`
                      }
                      // href={
                      //   weeklyDeals?.weekly_data?.[3]?.url?.startsWith("http")
                      //     ? weeklyDeals?.weekly_data?.[3]?.url
                      //     : `https://${weeklyDeals?.weekly_data?.[3]?.url}`
                      // }
                      // target="_blank"
                      // rel="noopener noreferrer"
                    ></a>
                    <h4
                      dangerouslySetInnerHTML={{
                        __html: weeklyDeals?.weekly_data?.[3]?.title || "",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default WeeklyDeals;

import React, { useEffect, useState } from "react";
import { Typography, Breadcrumbs } from "@mui/material";
import useTranslation from "../hooks/Translation";
import { useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "../helpers";
import { GoogleTranslateScript } from "../utils/GoogleTranslateScript";

const BreadcrumbComponent = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const pageLocation = window.location.pathname;
  const [path, setPath] = React.useState("");
  const [translatedPath, setTranslatedPath] = useState("");

  const targetLanguage = language === "ar" ? "ar" : "en";

  const formatRouteName = (route: string) => {
    return route
      .replace(/-/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const handleBreadCrumbClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (pageLocation) {
      const route = pageLocation.replace("/", "");
      const name = route.split("/");
      let newName = "";
      if (name?.length > 1) {
        newName = formatRouteName(name.slice(0, -1).join(" "));
      } else {
        newName = formatRouteName(route);
      }
      setPath(newName);
    }
  }, [pageLocation]);

  // const handleTranslate = async () => {
  //   if (path) {
  //     const translatedLanguage = await GoogleTranslateScript(
  //       path,
  //       targetLanguage
  //     );
  //     setTranslatedPath(translatedLanguage);
  //   }
  // };
  // useEffect(() => {
  //   handleTranslate();
  // }, [language, path]);

  return (
    <div className={pageLocation === "/" ? "no_brdcrmbs" : "mn_brdcrmbs"}>
      <div className="container">
        <div
          role="presentation"
          onClick={(e) => handleBreadCrumbClick(e)}
          style={{
            padding: "30px 0",

            zIndex: 1,
            cursor: "pointer",
          }}
        >
          <Breadcrumbs
            key="1"
            aria-label="breadcrumb"
            separator={path === "" ? "" : "›"}
          >
            <div
              onClick={() => {
                navigate("/");
              }}
              className={"brdcrmb"}
            >
              {translation.header.home || ""}
            </div>

            <Typography
              key="2"
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#820000",
                textTransform: "capitalize",
              }}
              color="text.primary"
            >
              {path}
            </Typography>
          </Breadcrumbs>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbComponent;

import React from "react";

type blogData = {
  image: string;
  date: string;
  author: string;
  heading: string;
  blogId: number;
  cat: number;
  description: string;
};

const BlogCard = ({
  image,
  date,
  author,
  heading,
  blogId,
  cat,
  description,
}: blogData) => {
  return (
    <div className="blog_crd">
      <figure>
        <img src={image} alt="Blog Image" />
      </figure>
      <div className="blg_dt">
        <p>{date}</p>
        <h5>{author}</h5>
      </div>
      <h3 dangerouslySetInnerHTML={{ __html: heading }} />
      <p dangerouslySetInnerHTML={{ __html: description }} />
      <a href={`/blog-detail/${blogId}`}>
        <span>Read More</span>
      </a>
    </div>
  );
};

export default BlogCard;

// @ts-nocheck
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import "./ViewProfile.scss";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { CommonBody, ProfileData } from "../../types/General";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Skeleton,
  TextField,
} from "@mui/material";

import {
  Button,
  Pagination,
  ProductCard,
  ProductCardSkeleton,
} from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import {
  useLazyGetProfileByIdQuery,
  usePostFollowUnfollowMutation,
} from "../../service/Product";
import { useParams } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import { useAddReportMutation } from "../../service/Cms";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import useTranslation from "../../hooks/Translation";
import useCategory from "../../hooks/useCategory";

const ViewProfile = () => {
  const categories = useCategory();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const style = generateResponsiveStyle();
  const { id } = useParams();
  const [favItems, setFavItems] = useState<any[]>([]);

  const [userData, setUserData] = useState<ProfileData>();
  const [activeCategory, setActiveCategory] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [report, setReport] = useState<string>("");
  const [addReport] = useAddReportMutation();
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [getProfileById] = useLazyGetProfileByIdQuery();
  const [postFollow, GetFollowData] = usePostFollowUnfollowMutation();
  const [error, setError] = useState<boolean>(false);
  const [open1, setOpen1] = useState(false);
  const ITEM_HEIGHT = 48;
  const open = Boolean(anchorEl);

  let totalPages = Math.ceil(totalCount / 68);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getProfile = async (id: string) => {
    setLoading(true);
    try {
      const response = await getProfileById({
        user_id: id,
        category_id: activeCategory,
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setUserData(response?.data || {});
        setTotalCount(response?.data?.productCount);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);

      errorToast(error?.data?.message || "");
    }
  };
  const handleFollowing = async (id: string) => {
    try {
      const response = await postFollow({
        user_id: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        try {
          const response = await getProfileById({
            user_id: id,
            category_id: activeCategory,
            page: page,
          }).unwrap();
          if (response?.statusCode === 200) {
            setUserData(response?.data || {});
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const checkFavProperties = () => {
    const fav_data = userData?.products
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  //handle report
  const handleReport = async (id: string) => {
    setError(true);
    if (!report) {
      return;
    }
    let data = {
      reason: report,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;

      const response = await addReport({ id, encryptedBody }).unwrap();
      if (response?.statusCode === 200) {
        successToast(translation.toast_messages.report_sent);
        setReport("");
        setError(false);
        setOpen1(false);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
      console.log(error);
    }
  };

  useEffect(() => {
    checkFavProperties();
  }, [userData?.products]);

  useEffect(() => {
    if (id) {
      getProfile(id);
    }
  }, [activeCategory, page]);
  return (
    <Layout>
      <Loader isLoad={GetFollowData?.isLoading} />
      <div className="container vw_prfl">
        {loading ? (
          <div className="vw_lft">
            <div className="vw_lft_top">
              <figure>
                <Skeleton
                  variant="circular"
                  animation="wave"
                  height={"100%"}
                  width={"100%"}
                />
              </figure>
              <h3>
                {" "}
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={"100%"}
                  style={{ fontSize: "1rem" }}
                />
              </h3>
            </div>
            <ul className="vw_ul">
              {Array.from({ length: 3 }, (_, index) => (
                <li key={index}>
                  <p>
                    {" "}
                    <Skeleton
                      variant="text"
                      animation="wave"
                      width={"100%"}
                      style={{ fontSize: "1rem" }}
                    />{" "}
                    <span>
                      {" "}
                      <Skeleton
                        variant="text"
                        animation="wave"
                        width={"100%"}
                        style={{ fontSize: "1rem" }}
                      />
                    </span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="vw_lft">
            <div className="vw_lft_top">
              <figure>
                <img
                  src={
                    userData?.user?.image ||
                    "/static/images/user_placeholder.png"
                  }
                  alt="user_placeholder"
                />
              </figure>
              <h3>{userData?.user?.fullName || ""}</h3>
            </div>
            <ul className="vw_ul">
              <li>
                <p>
                  {" "}
                  {translation.view_profile.total_products}{" "}
                  <span> {userData?.productCount || 0}</span>
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  {translation.view_profile.total_followers}{" "}
                  <span> {userData?.followCount?.followersCount || 0}</span>
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  {translation.view_profile.total_following}{" "}
                  <span>{userData?.followCount?.followingCount || 0}</span>
                </p>
              </li>
            </ul>
            <Button
              value={
                userData?.isFollow
                  ? translation.view_profile.unfollow
                  : translation.view_profile.follow
              }
              onClick={() => handleFollowing(userData?.user?._id)}
            ></Button>
          </div>
        )}

        {loading ? (
          <div className="vw_ryt">
            <div className="vw_prfl_hdng">
              <h2>
                {" "}
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={150}
                  style={{ fontSize: "2rem" }}
                />
              </h2>
              <Skeleton
                variant="circular"
                animation="wave"
                width={40}
                height={40}
              />
            </div>
            <ul className="categoty_fltrs">
              {Array.from({ length: 5 }, (_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  animation="wave"
                  width={100}
                  height={20}
                  style={{ borderRadius: "40px" }}
                />
              ))}
            </ul>
            <div className="prdcts_sc">
              {Array.from({ length: 8 }, (_, index) => (
                <ProductCardSkeleton key={index} />
              ))}
            </div>
          </div>
        ) : (
          <div className="vw_ryt">
            <div className="vw_prfl_hdng">
              <h2>{translation.view_profile.my_products}</h2>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{
                  position: "relative",
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                className="account_item"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: "12ch",
                  },
                }}
              >
                <RWebShare
                  data={{
                    text: "Profile",
                    // url: `https://www.krafo.com/view-profile/${id}`, //live
                    url: `https://dwmweb.appgrowthcompany.com/view-profile/${id}`, //dev
                    title: translation.view_profile.share_profile,
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <MenuItem>{translation.view_profile.share_profile}</MenuItem>
                </RWebShare>

                <MenuItem onClick={() => setOpen1(true)}>
                  {translation.view_profile.report}
                </MenuItem>
              </Menu>
            </div>
            <ul className="categoty_fltrs">
              <li
                onClick={() => setActiveCategory("")}
                className={
                  activeCategory === "" ? "fltrs_cat actv_cat" : "fltrs_cat"
                }
              >
                <h4>{translation.Globals.all}</h4>
              </li>
              {categories?.map((item) => {
                return (
                  <li
                    key={item?._id}
                    onClick={() => setActiveCategory(item?._id)}
                    className={
                      activeCategory === item?._id
                        ? "fltrs_cat actv_cat"
                        : "fltrs_cat"
                    }
                  >
                    <h4>
                      {language === "ar"
                        ? item?.name_ar || ""
                        : item?.name || ""}
                    </h4>
                  </li>
                );
              })}
            </ul>
            <div className="prdcts_sc">
              {userData?.products?.length ? (
                userData?.products?.map((item) => {
                  return (
                    <ProductCard
                      key={item?._id}
                      product_id={item?._id}
                      product_image={item?.images[0]}
                      product_feature={
                        language === "ar"
                          ? item?.category?.name_ar
                          : item?.category?.name
                      }
                      product_name={item?.name}
                      product_price={item?.price}
                      location={item?.address}
                      setFavItems={setFavItems}
                      favItems={favItems}
                      item={item}
                      booster_applied={item?.boosterApplied}
                      lang={item?.lang}
                    />
                  );
                })
              ) : (
                <div className="noData top_mt">
                  <figure>
                    <img src="/static/images/empty.png" alt="empty" />
                  </figure>
                  <h3>{translation.Globals.no_product}</h3>
                </div>
              )}
            </div>
            <div className="vw_prfl_pgntn">
              <Pagination
                module={userData?.products}
                page={page || 1}
                onPageChange={onPageChange}
                totalPages={totalPages}
                setPage={setPage}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        // BackdropClick={false}

        open={open1}
        onClose={() => setOpen1(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpen1(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.view_profile.user_report}
            </h2>
            <div className="logut_flx1">
              <TextField
                style={{ margin: 0 }}
                type="text"
                className="text_field text_area"
                hiddenLabel
                variant="outlined"
                placeholder={translation.view_profile.your_reason}
                multiline
                rows={4}
                fullWidth
                name="description"
                id="description"
                value={report}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    setReport(val.target.value);
                  }
                }}
              />
            </div>
            {error && !report ? (
              <h6 className="err_msg">
                {translation.validations.required_field}
              </h6>
            ) : (
              ""
            )}
            <div style={{ width: "50%", margin: "auto" }}>
              <Button
                value={translation.view_profile.report}
                onClick={() => handleReport(id)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </Layout>
  );
};

export default ViewProfile;

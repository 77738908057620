import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { ChatHistoryResponse, CommonBody } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import {
  useLazyGetChatListQuery,
  usePostAcceptRejectOfferMutation,
} from "../../service/Product";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { GoogleTranslateScript } from "../../utils/GoogleTranslateScript";

type props = {
  chatHistory: ChatHistoryResponse[];
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  sendMessage: () => void;
  selectedReceiverName: string;
  selectedReceiverImage: string;
  deleteChat: () => void;
  setChatHistory: Dispatch<SetStateAction<ChatHistoryResponse[]>>;
  selectedReceiverId: string;
  tab: number;
  selectedProductName: string;
  setOpenMobileChat: Dispatch<SetStateAction<boolean>>;
  leaveRoom: () => void;
  productLang?: number;
};

let enable = true;

const ChatDetail = ({
  chatHistory,
  text,
  setText,
  sendMessage,
  selectedReceiverName,
  selectedReceiverImage,
  deleteChat,
  setChatHistory,
  selectedReceiverId,
  tab,
  selectedProductName,
  setOpenMobileChat,
  leaveRoom,
  productLang,
}: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const userData = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const ITEM_HEIGHT = 48;
  const [acceptRejectOffer, GetAcceptRejectOfferData] =
    usePostAcceptRejectOfferMutation();
  const [getChatList] = useLazyGetChatListQuery();

  const [translatedProductName, setTranslatedProductName] = useState("");

  const targetLanguage = language === "ar" ? "ar" : "en";

  // const handleTranslate = async () => {
  //   if (selectedProductName) {
  //     const translatedText = await GoogleTranslateScript(
  //       selectedProductName,
  //       targetLanguage
  //     );
  //     setTranslatedProductName(translatedText);
  //   }
  // };
  // useEffect(() => {
  //   handleTranslate();
  // }, [language, selectedProductName]);

  const handleAcceptOffer = async (
    productId: string | undefined,
    roomId: string | undefined,
    amount: number | undefined
  ) => {
    if (!enable) {
      return;
    }
    enable = false;

    const data = {
      response: "Accept",
      amount: amount,
      chatRoomId: roomId,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await acceptRejectOffer({
        product_id: productId,
        body: encryptedBody,
      }).unwrap();

      if (response?.statusCode === 200) {
        try {
          const response = await getChatList({
            roomId: roomId ? roomId : "",
            filter: tab,
          }).unwrap();

          if (response?.statusCode === 200) {
            setChatHistory(response?.data?.chat || []);
            enable = true;
          }
        } catch (error: any) {}
      }
    } catch (error: any) {}
  };
  const handleRejectOffer = async (
    productId: string | undefined,
    roomId: string | undefined,
    amount: number | undefined
  ) => {
    if (!enable) {
      return;
    }
    enable = false;

    const data = {
      response: "Reject",
      amount: amount,
      chatRoomId: roomId,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const response = await acceptRejectOffer({
        product_id: productId,
        body: encryptedBody,
      }).unwrap();

      if (response?.statusCode === 200) {
        try {
          const response = await getChatList({
            roomId: roomId ? roomId : "",
            filter: tab,
          }).unwrap();

          if (response?.statusCode === 200) {
            setChatHistory(response?.data?.chat || []);
            enable = true;
          }
        } catch (error: any) {}
      }
    } catch (error: any) {}
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteChat();
    setAnchorEl(null);
  };
  const handleMessageInput = (e: any) => {
    setText(e.target.value);
  };

  const keyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };
  // Group messages by date
  const messagesByDate: { [date: string]: ChatHistoryResponse[] } = {};
  chatHistory?.forEach((message) => {
    const date = new Date(message.createdAt).toLocaleDateString();
    if (!messagesByDate[date]) {
      messagesByDate[date] = [];
    }
    messagesByDate[date].push(message);
  });

  return (
    <div className="main">
      <Loader isLoad={GetAcceptRejectOfferData?.isLoading} />
      <div className="mob_view_back">
        <img
          src="/static/images/back.png"
          alt="back"
          onClick={() => {
            setOpenMobileChat(false);
            leaveRoom();
          }}
        />
      </div>

      <h2 style={{ color: "#820000", fontSize: "22px", paddingBottom: "10px" }}>
        {/* {language === "en" ? (
          <>
            {productLang === 1
              ? selectedProductName
              : translatedProductName || ""}
          </>
        ) : (
          <>
            {productLang === 2
              ? selectedProductName
              : translatedProductName || ""}
          </>
        )} */}
        {selectedProductName || "-"}
      </h2>
      <div className="heading">
        <div className="userDetail">
          <figure
            onClick={() => navigate(`/view-profile/${selectedReceiverId}`)}
          >
            <img
              src={
                selectedReceiverImage || "/static/images/user_placeholder.png"
              }
              alt="user"
            />
          </figure>
          <div className="username">
            <h3>{selectedReceiverName || ""}</h3>
          </div>
        </div>

        {chatHistory?.length ? (
          <>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={{
                position: "relative",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              className="account_item"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "12ch",
                },
              }}
            >
              <MenuItem onClick={handleDelete}>
                {translation.chat.delete_chat}
              </MenuItem>
            </Menu>
          </>
        ) : null}
      </div>
      <div className="chat-div">
        {Object.entries(messagesByDate)?.map(([date, messages]) => (
          <>
            <div key={date}>
              <div className="time">
                <p>{date}</p>
              </div>

              {messages?.length
                ? messages?.map((item) =>
                    item?.offer && item?.senderId !== userData?._id ? (
                      <div className="ofr_prc" key={item._id}>
                        <div className="ofr_prc_top">
                          <p>{translation.chat.offer_price || ""}</p>
                          <h6>{item?.product || ""}</h6>
                          <h4>
                            {translation.Globals.aed} {item?.amount || 0}
                          </h4>
                        </div>
                        <div className="ofr_prc_btm">
                          <h3
                            onClick={() =>
                              handleRejectOffer(
                                item?.productId,
                                item?.roomId,
                                item?.amount
                              )
                            }
                          >
                            {translation.chat.decline}
                          </h3>
                          <h3
                            onClick={() =>
                              handleAcceptOffer(
                                item?.productId,
                                item?.roomId,
                                item?.amount
                              )
                            }
                          >
                            {translation.chat.accept || ""}
                          </h3>
                        </div>
                      </div>
                    ) : item?.forLang && item?.senderId !== userData?._id ? (
                      language === "ar" && item?.forLang ? (
                        <div
                          key={item._id}
                          className={
                            item?.senderId === userData?._id
                              ? "out-going"
                              : "incoming"
                          }
                        >
                          <p>{item?.text_ar2 || ""}</p>
                        </div>
                      ) : (
                        <div
                          key={item._id}
                          className={
                            item?.senderId === userData?._id
                              ? "out-going"
                              : "incoming"
                          }
                        >
                          <p>{item?.text2 || ""}</p>
                        </div>
                      )
                    ) : language === "ar" && item?.forLang ? (
                      <div
                        key={item._id}
                        className={
                          item?.senderId === userData?._id
                            ? "out-going"
                            : "incoming"
                        }
                      >
                        <p>{item?.text_ar || ""}</p>
                      </div>
                    ) : (
                      <div
                        key={item._id}
                        className={
                          item?.senderId === userData?._id
                            ? "out-going"
                            : "incoming"
                        }
                      >
                        <p>{item?.text || ""}</p>
                      </div>
                    )
                  )
                : null}
            </div>
          </>
        ))}
        <div id="scrollTo" />
      </div>

      <div className="chat_foot">
        <TextField
          hiddenLabel
          placeholder={translation.chat.type_message}
          fullWidth
          name="text"
          type="text"
          variant="outlined"
          multiline
          onChange={(val) => {
            if (val.target.value === " " || val.target.value === ".") {
            } else {
              handleMessageInput(val);
            }
          }}
          value={text}
          onKeyDown={(e) => {
            if (text !== "") {
              keyDownHandler(e);
            }
          }}
        />
        <SendIcon
          onClick={() => {
            if (text !== "") {
              sendMessage();
            }
          }}
        />
      </div>
    </div>
  );
};

export default ChatDetail;

import { useEffect, useState } from "react";
import Layout from "../../layout";
import "../contactUs/Cms.scss";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/store";
import { getCms, resetCms } from "../../reducers/generalSlice";
import useTranslation from "../../hooks/Translation";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../helpers";
import { useLazyGetCmsQuery } from "../../service/Cms";

const AboutUs = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const navigate = useNavigate();
  const getCmsData = useAppSelector(getCms);

  const dispatch = useAppDispatch();
  const [GetCms] = useLazyGetCmsQuery();
  const [loading, setLoading] = useState(false);

  const fetchCmsData = async () => {
    try {
      setLoading(true);
      const response = await GetCms({}).unwrap();
      setLoading(false);
      if (response?.statusCode === 200) {
        dispatch(resetCms({ cms: response?.data }));
      }
    } catch (error: any) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCmsData();
  }, []);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <div className=" about_us">
        <div className="about_flex">
          <div className="about_lft">
            <div className="about_lft_udr">
              <h1>{translation.Globals.what_krafo}</h1>
              <p>{translation.footer.text || ""}</p>
            </div>
          </div>
          <div className="about_rgt">
            <figure>
              <img src="/static/images/about_us.png" alt="about_us" />
            </figure>
          </div>
        </div>

        <div className="about_td">
          <h2>{translation.footer.text || ""}</h2>

          <Button
            value={translation.Globals.get_started}
            onClick={() => navigate("/")}
          />
        </div>

        <div className="common_policy ">
          <div className="about_content container">
            <h2>{translation.footer.about_us}</h2>
            <p
              className={getCmsData ? "about_txt" : "about_txt2"}
              dangerouslySetInnerHTML={{
                __html: getCmsData
                  ? language === "ar"
                    ? getCmsData?.about_us_ar
                    : getCmsData?.about_us
                  : translation.Globals.no_info,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

// @ts-nocheck
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZoomInIcon from "@mui/icons-material/ZoomIn";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "../../components/Components.scss";
import "react-lazy-load-image-component/src/effects/blur.css";

import { useEffect, useState } from "react";
import useTranslation from "../../hooks/Translation";
import { FeaturedProductsData, ProductBannerData } from "../../types/General";

import QuickView from "../../components/QuickView";
import { GoogleTranslateScript } from "../../utils/GoogleTranslateScript";
import { Skeleton } from "@mui/material";

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  rows: 2,
  slidesPerRow: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        rows: 1,
        slidesPerRow: 3,
      },
    },
    {
      breakpoint: 400,
      settings: {
        rows: 1,
        slidesPerRow: 2,
      },
    },
  ],
};

type props = {
  productBannerData: ProductBannerData[];
  newProducts: any[];
  newCategories: any[];
  arrivalLoading: boolean;
};

const Arrivals = ({
  productBannerData,
  newCategories,
  newProducts,
  arrivalLoading,
}: props) => {
  const translation = useTranslation() as any;
  const language = getFromStorage(STORAGE_KEYS.language);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<FeaturedProductsData>();
  const [selectedImage, setSelectedImage] = useState("");

  const [translatedProductName, setTranslatedProductName] = useState("");

  const targetLanguage = language === "ar" ? "ar" : "en";

  // const handleTranslate = async () => {
  //   const productNameArray = newProducts
  //     ?.map((item) => item?.name)
  //     .filter(Boolean);

  //   if (productNameArray?.length > 0) {
  //     const translatedTexts = await Promise.all(
  //       productNameArray.map(async (productName) => {
  //         const translatedText = await GoogleTranslateScript(
  //           productName,
  //           targetLanguage
  //         );
  //         return translatedText;
  //       })
  //     );
  //     setTranslatedProductName(translatedTexts);
  //   }
  // };
  // useEffect(() => {
  //   handleTranslate();
  // }, [language, newProducts]);

  const handleOpenPopup = (e: any, item: FeaturedProductsData) => {
    e.stopPropagation();
    e.preventDefault();
    setSelectedImage(item?.images[0]);
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <>
      <section className="prodcts_sc hm_arrivals container ">
        <div className="prdct_hdngs ">
          <h2>{translation.Globals.new_arrivals}</h2>

          <Link to={`/all-products/${"arrival"}`}>
            <p>{translation.Globals.see_all}</p>
          </Link>
        </div>
        <div className="arrival_flex">
          {!arrivalLoading ? (
            <div className="w_33">
              <h3>{translation?.Globals?.products}</h3>
              <Slider {...settings}>
                {newProducts?.length
                  ? newProducts?.map((item, index) => {
                      return (
                        <li key={item?._id}>
                          <Link
                            to={`/product-detail/${item?._id}`}
                            className="prdct_crd"
                          >
                            <figure className="prdct_img">
                              <figure
                                className="zoom_icon"
                                onClick={(e) => handleOpenPopup(e, item)}
                              >
                                <ZoomInIcon />
                              </figure>

                              <LazyLoadImage
                                alt="prdct_img"
                                src={item?.images[0]}
                                effect="blur"
                                height={"100%"}
                                width={"100%"}
                              />
                            </figure>
                            <div className="prdct_dtl">
                              <h3>
                                {/* {" "}
                                {language === "en" ? (
                                  <>
                                    {item?.lang === 1
                                      ? item?.name
                                      : translatedProductName[index] || ""}
                                  </>
                                ) : (
                                  <>
                                    {item?.lang === 2
                                      ? item?.name
                                      : translatedProductName[index] || ""}
                                  </>
                                )}{" "} */}

                                {item?.name || "-"}
                              </h3>
                              <h4>
                                {" "}
                                {translation.Globals.aed}{" "}
                                {item?.price.toLocaleString("en-IN") || 0}
                              </h4>
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </Slider>
            </div>
          ) : (
            <div className="w_33">
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"90px"}
                animation="wave"
              />
              <div className="prdct_crd_nar_flx">
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
              </div>
            </div>
          )}

          {!arrivalLoading ? (
            <div className="w_33">
              <h3>{translation.Globals.categories}</h3>
              <Slider {...settings}>
                {newCategories?.length
                  ? newCategories?.map((item) => {
                      return (
                        <li key={item?._id}>
                          <Link
                            to={{
                              pathname: `/products/${item?._id}`,
                              search: `?name_ar=${encodeURIComponent(
                                item?.name_ar || ""
                              )}&name_en=${encodeURIComponent(
                                item?.name || ""
                              )}&subCat=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
                            }}
                            className="prdct_crd mn_ht_190"
                          >
                            <figure className="prdct_img">
                              <LazyLoadImage
                                alt="prdct_img"
                                src={item?.image[0]}
                                effect="blur"
                                height={"100%"}
                                width={"100%"}
                              />
                            </figure>
                            <div className="prdct_dtl">
                              <h3>
                                {language === "en" ? item?.name : item?.name_ar}
                              </h3>
                              <h4 />
                            </div>
                          </Link>
                        </li>
                      );
                    })
                  : null}
              </Slider>
            </div>
          ) : (
            <div className="w_33">
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"90px"}
                animation="wave"
              />
              <div className="prdct_crd_nar_flx">
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
              </div>
            </div>
          )}

          {!arrivalLoading ? (
            <div className="w_33">
              <h3>{translation.Globals.in_focus}</h3>
              {productBannerData?.filter((item) => item?.type === 3)[0]
                ?.webImage ? (
                <>
                  {productBannerData?.filter((item) => item?.type === 3)[0]
                    ?.link?.length ? (
                    <a
                      href={
                        productBannerData
                          ?.filter((item) => item?.type === 3)[0]
                          ?.link?.startsWith("http")
                          ? productBannerData?.filter(
                              (item) => item?.type === 3
                            )[0]?.link
                          : `https://${
                              productBannerData?.filter(
                                (item) => item?.type === 3
                              )[0]?.link
                            }`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="infocus_image infocus_image_1">
                        <img
                          src={
                            productBannerData?.filter(
                              (item) => item?.type === 3
                            )[0]?.webImage
                          }
                          alt="banner"
                        />
                      </figure>
                    </a>
                  ) : (
                    <span>
                      <figure className="infocus_image infocus_image_1">
                        <img
                          src={
                            productBannerData?.filter(
                              (item) => item?.type === 3
                            )[0]?.webImage
                          }
                          alt="banner"
                        />
                      </figure>
                    </span>
                  )}
                </>
              ) : (
                <a href="#">
                  <figure className="infocus_image">
                    <img
                      src="/static/images/bnr_placeholder.png"
                      alt="banner"
                    />
                  </figure>
                </a>
              )}
              {productBannerData?.filter((item) => item?.type === 3)[1]
                ?.webImage ? (
                <>
                  {productBannerData?.filter((item) => item?.type === 3)[1]
                    ?.link?.length ? (
                    <a
                      href={
                        productBannerData
                          ?.filter((item) => item?.type === 3)[1]
                          ?.link?.startsWith("http")
                          ? productBannerData?.filter(
                              (item) => item?.type === 3
                            )[1]?.link
                          : `https://${
                              productBannerData?.filter(
                                (item) => item?.type === 3
                              )[1]?.link
                            }`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="infocus_image">
                        <img
                          src={
                            productBannerData?.filter(
                              (item) => item?.type === 3
                            )[1]?.webImage
                          }
                          alt="banner"
                        />
                      </figure>
                    </a>
                  ) : (
                    <span>
                      <figure className="infocus_image">
                        <img
                          src={
                            productBannerData?.filter(
                              (item) => item?.type === 3
                            )[1]?.webImage
                          }
                          alt="banner"
                        />
                      </figure>
                    </span>
                  )}
                </>
              ) : (
                <a href="#">
                  <figure className="infocus_image">
                    <img
                      src="/static/images/bnr_placeholder.png"
                      alt="banner"
                    />
                  </figure>
                </a>
              )}
            </div>
          ) : (
            <div className="w_33">
              <div className="prdct_crd_nar_flx">
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
                <div className="prdct_crd_nar">
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"100%"}
                    animation="wave"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <QuickView
          open={open}
          setOpen={setOpen}
          item={selectedItem}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      </section>
      {/* ) : null} */}
    </>
  );
};

export default Arrivals;

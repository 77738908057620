import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Button, InputField } from "../../components";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import "../auth/Auth.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useAppDispatch } from "../../hooks/store";
import { toggleAuthModal } from "../../reducers/generalSlice";
import {
  useLazyGetProfileQuery,
  usePostLoginMutation,
  usePostSocialLoginMutation,
} from "../../service/Auth";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../helpers";
import { setCredentials, temporaryToken } from "../../reducers/authSlice";
import app from "../../utils/firebaseKeys";
import useTranslation from "../../hooks/Translation";

type props = {
  closeModal: () => void;
  setPhone: Dispatch<SetStateAction<boolean>>;
  setSignUp: Dispatch<SetStateAction<boolean>>;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  setDialCode: Dispatch<SetStateAction<string>>;
};
const provider = new GoogleAuthProvider();

const Login = ({
  closeModal,
  setPhone,
  setSignUp,
  setPhoneNumber,
  setDialCode,
}: props) => {
  const translation = useTranslation() as any;
  const auth = getAuth(app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState(false);
  const [countryName, setCountryName] = useState("in");
  const [phoneCode, setPhoneCode] = useState("+971");
  const [postLogin, { isLoading }] = usePostLoginMutation();
  const [getProfile] = useLazyGetProfileQuery();
  const [postSocialLogin] = usePostSocialLoginMutation();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getProfile({}).unwrap();
      if (result?.statusCode === 200) {
        dispatch(
          setCredentials({
            token: JSON.parse(`${token}`),
            user: result?.data || null,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleForgot = () => {
    setPhone(false);
    setSignUp(false);
    closeModal();
    dispatch(
      toggleAuthModal({ isAuthModalVisible: true, ModalType: "forgot" })
    );
  };

  const handleChangePhone = (phone: any, country: any) => {
    setPhoneCode(country?.dialCode);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setCountryName(country?.countryCode);
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      phone: email
        ? Yup.string().notRequired()
        : Yup.string()
            .required(translation.validations.required_field)
            .min(8, translation.validations.correct_phone)
            .max(14)
            .label(translation.Globals.phone_number),

      email: email
        ? Yup.string()
            .required(translation.validations.email_required)
            .matches(
              /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              translation.validations.valid_email
            )
        : Yup.string().notRequired(),
      password: email
        ? Yup.string()
            .required(translation.validations.password_required)
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
              translation.validations.password_validation
            )
        : Yup.string().notRequired(),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);

      let data = {
        ...(email
          ? {
              email: formik.values.email,
              password: formik.values.password,
            }
          : {
              phone: formik.values.phone,
              countryCode: (phoneCode.includes("+") ? "" : "+") + phoneCode,
            }),
        deviceType: "WEB",
        deviceToken: fcmtoken || "1234",
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postLogin(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (email) {
            setToStorage(
              STORAGE_KEYS.token,
              JSON.stringify(response?.data?.token)
            );
            setToStorage(
              STORAGE_KEYS.userData,
              JSON.stringify(response?.data || "")
            );
            dispatch(
              setCredentials({
                user: response?.data || null,
                token: response?.data?.token || "",
              })
            );
            dispatch(
              temporaryToken({
                tempToken: null,
              })
            );
            removeFromStorage(STORAGE_KEYS.tempToken);
            closeModal();
            if (response?.data?.isProfileComplete) {
              navigate("/");
            } else {
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: true,
                  ModalType: "profileSetup",
                })
              );
            }
            getUserDetails();
            if (formik.values.remember) {
              setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
            } else {
              removeFromStorage(STORAGE_KEYS.credentials);
            }
          } else {
            closeModal();
            setPhone(true);
            setPhoneNumber(formik.values.phone || "");
            setDialCode((phoneCode.includes("+") ? "" : "+") + phoneCode);
            dispatch(
              toggleAuthModal({
                isAuthModalVisible: true,
                ModalType: "verification",
              })
            );
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);

      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.email);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      const deviceId = navigator.userAgent;

      const data = {
        email: user?.email,
        fullName: user?.displayName,
        image: user?.photoURL,
        socialId: user?.uid,
        socialType: "GOOGLE",
        deviceType: "WEB",
        deviceId: deviceId,
        deviceToken: fcmtoken || "1234",
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await postSocialLogin(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          setToStorage(
            STORAGE_KEYS.token,
            JSON.stringify(response?.data?.token)
          );
          setToStorage(
            STORAGE_KEYS.userData,
            JSON.stringify(response?.data || "")
          );
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: response?.data?.token || "",
            })
          );
          dispatch(
            temporaryToken({
              tempToken: null,
            })
          );
          removeFromStorage(STORAGE_KEYS.tempToken);
          closeModal();
          if (response?.data?.isProfileComplete) {
            navigate("/");
          } else {
            dispatch(
              toggleAuthModal({
                isAuthModalVisible: true,
                ModalType: "profileSetup",
              })
            );
          }
          getUserDetails();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
        console.log(error);
      }
    } catch (error) {
      console.error("Google Login Error:", error);
    }
  };

  const keyDownHandler = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      formik.handleSubmit();
    }
  };
  return (
    <div>
      <div className="AuthWrap">
        <Loader isLoad={isLoading} />
        <div className="cross">
          <CloseIcon
            onClick={() =>
              dispatch(
                toggleAuthModal({
                  isAuthModalVisible: false,
                  ModalType: "",
                })
              )
            }
          />
        </div>
        <h2>{translation.Globals.sign_in}</h2>
        <p>{translation.Auth.Login.enter_details}</p>
        <form onSubmit={formik.handleSubmit}>
          {email ? (
            <>
              <InputField
                placeholder={translation.Auth.Login.email_id}
                name="email"
                id="login_email"
                inputProps={{ maxLength: 80 }}
                value={formik.values.email}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.email && formik.errors.email}
              />
              <InputField
                placeholder={translation.Auth.Login.password}
                password
                name="password"
                id="password"
                value={formik.values.password}
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={formik.touched.password && formik.errors.password}
              />{" "}
            </>
          ) : (
            <div className="form_control">
              <PhoneInput
                country={"in"}
                value={phoneCode + formik.values.phone}
                onChange={(phone: any, country: any) =>
                  handleChangePhone(phone, country)
                }
                onBlur={formik.handleBlur}
                placeholder="0 (000) 000-000"
                enableSearch={true}
                inputStyle={{
                  width: "100%",
                  backgroundColor: "#EBEBEB",
                }}
                onKeyDown={(e) => {
                  if (formik.values.phone) {
                    keyDownHandler(e);
                  }
                }}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <h6 className="err_msg">
                  {formik.touched.phone && formik.errors.phone}
                </h6>
              ) : (
                ""
              )}
              <div style={{ marginBottom: 10 }} />
            </div>
          )}
          {email ? (
            <div className="remmerbar">
              <FormControlLabel
                control={<Checkbox />}
                checked={formik.values.remember}
                name="remember"
                onChange={formik.handleChange}
                label={translation.Auth.Login.remember}
              />

              <div className="anchor_link">
                <h3 onClick={handleForgot}>
                  {translation.Auth.Login.forgot_pas}
                </h3>
              </div>
            </div>
          ) : null}

          <Button value={translation.Globals.Continue} />
        </form>
        <div className="continue">
          <h4
            onClick={() => {
              !email ? setPhone(false) : setPhone(true);
              setEmail(!email);
            }}
          >
            {translation.Auth.Login.continue_with}{" "}
            {!email
              ? `${translation.Globals.email}`
              : `${translation.Globals.phone_number}`}
          </h4>
        </div>
        <div className="Or_div">
          <p>{translation.Auth.Login.or}</p>
        </div>
        <div className="social_icn">
          <figure onClick={signInWithGoogle}>
            <img src="/static/images/google.svg" alt="google" />
          </figure>
        </div>
        <div className="signUp">
          <h4>
            {translation.Auth.Login.not_member}{" "}
            <span
              onClick={() => {
                dispatch(
                  toggleAuthModal({
                    isAuthModalVisible: true,
                    ModalType: "signUp",
                  })
                );
                setSignUp(true);
              }}
            >
              {translation.Globals.sign_up}
            </span>
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Login;

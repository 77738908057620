import { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import { STORAGE_KEYS, getFromStorage } from "./helpers";
import { useAppDispatch, useAppSelector } from "./hooks/store";
import { getToken, setCredentials, temporaryToken } from "./reducers/authSlice";
import { firebaseCloudMessaging } from "./utils/firebase";
import { toast } from "react-toastify";
import LanguageContextProvider from "./context/LanguageContext";

import { useLazyGetProfileQuery } from "./service/Auth";
import { useNavigate } from "react-router-dom";
import { socket } from "./utils/socket";

function App() {
  const navigate = useNavigate();
  const socketToken = useAppSelector(getToken);
  const language = getFromStorage(STORAGE_KEYS.language);
  const dispatch = useAppDispatch();

  const [getProfile] = useLazyGetProfileQuery();

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const tempToken = getFromStorage(STORAGE_KEYS.tempToken);

    if (token) {
      try {
        const result = await getProfile({}).unwrap();
        if (result?.statusCode === 200) {
          dispatch(
            setCredentials({
              token: JSON.parse(`${token}`),
              user: result?.data || null,
            })
          );
        }
        // else if (result?.statusCode === 503) {
        //   navigate("/server-maintenance");
        // }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const result = await getProfile({}).unwrap();
        if (result?.statusCode === 200) {
          dispatch(
            temporaryToken({
              tempToken: JSON.parse(`${tempToken}`),
            })
          );
        }
        // else if (result?.statusCode === 503) {
        //   navigate("/server-maintenance");
        // }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    const userData = getFromStorage(STORAGE_KEYS.userData);

    if (token) {
      dispatch(
        setCredentials({
          token: JSON.parse(`${token}`),
          user: JSON.parse(`${userData}`) || null,
        })
      );
      getUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function setToken() {
      try {
        const fcmToken = await firebaseCloudMessaging.init();
        if (fcmToken) {
          console.log("fcm_Token", fcmToken);
        }
      } catch (error) {
        console.log(error);
      }
    }
    setToken();

    const playNotificationSound = () => {
      const audio = new Audio("/static/sounds/notification_sound.mp3"); // Specify the path to your notification sound
      audio.play();
    };

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        try {
          event?.source?.postMessage("Hi client");
          console.log("event for the service worker", event);
          playNotificationSound();
          return toast(
            <div style={{ height: "100%" }}>
              <div
                style={{ color: "#820000" }}
                onClick={() => navigate("/notifications")}
              >
                {language === "ar"
                  ? event?.data?.data?.title_ar
                  : event?.data?.data?.title}
              </div>
              <div
                style={{ color: "#1d1d1d", paddingTop: "10px" }}
                onClick={() => navigate("/notifications")}
              >
                {language === "ar"
                  ? event?.data?.data?.message_sr
                  : event?.data?.data?.message}
              </div>
            </div>
          );
        } catch (e) {
          console.log(e, "error");
          return e;
        }
      });
    }

    if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "notifications" })
        .then(function (notificationPerm) {
          notificationPerm.onchange = function () {
            if (notificationPerm.state === "granted") {
              setToken();
              if (window) {
                window.location.reload();
              }
            }
          };
        });
    }
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener("message", (e) =>
          console.log(e)
        );
      }
    };
  }, []);

  function onConnect() {
    console.log("connected");
  }

  function onDisconnect() {
    console.log("disconnected");
  }

  function onError(error: any) {
    console.error("Socket connection error:", error);
  }

  useEffect(() => {
    if (socketToken?.length) {
      const modifiedURL = `${socket.io.uri}?token=${socketToken || ""}`;
      socket.io.uri = modifiedURL;

      if (!socket?.connected) {
        socket.connect();
      }

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("error", onError);

      return () => {
        socket.disconnect();
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("error", onError);
      };
    } else {
      socket.disconnect();
    }
  }, [socketToken]);

  return (
    <>
      <LanguageContextProvider>
        <Routing />
      </LanguageContextProvider>
    </>
  );
}

export default App;

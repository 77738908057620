import React, { useEffect, useState } from "react";
import { InputField, Pagination, ProductListingCard } from "../../components";
import { Box, Button, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import cardData from "../../data/ProductData.json";
import {
  useDeletePropertyByIdMutation,
  useLazyGetMyProductQuery,
} from "../../service/Product";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { Category, globalSearch } from "../../types/General";

import { generateResponsiveStyle } from "../../utils/AuthModalStyle";
import CloseIcon from "@mui/icons-material/Close";
import useTranslation from "../../hooks/Translation";
import useCategory from "../../hooks/useCategory";
import { useLazyGetFilteredCategoriesQuery } from "../../service/category";

const MyListing = () => {
  // const categories = useCategory();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const style = generateResponsiveStyle();
  const [activeCategory, setActiveCategory] = useState<string>("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [getListing, { isLoading }] = useLazyGetMyProductQuery();
  const [tab, setTab] = useState(1);
  const [categories, setCategories] = useState<Category[]>([]);

  const [MyListing, setMyListing] = useState<globalSearch[]>([]);

  const [getCategories, GetCategoriesData] =
    useLazyGetFilteredCategoriesQuery();

  let totalPages = Math.ceil(totalCount / 4);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllCategories = async () => {
    try {
      const response = await getCategories({ type: tab }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.categoryObjects || []);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getAllProperty = async () => {
    try {
      const response = await getListing({
        type: tab,
        categoryId: activeCategory,
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMyListing(response?.data?.products || []);
        setTotalCount(response?.data?.count);
      } else {
        setMyListing([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };
  const [deleteProperty] = useDeletePropertyByIdMutation();
  const handleDeleteProperty = async (e: any, id: string) => {
    e.stopPropagation();
    try {
      const res = await deleteProperty({ id }).unwrap();
      if (res?.statusCode === 200) {
        successToast(translation.toast_messages.product_deleted || "");
        getAllProperty();
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllProperty();
  }, [activeCategory, page, tab]);

  useEffect(() => {
    getAllCategories();
  }, [tab]);

  return (
    <div className="main">
      <Loader isLoad={isLoading || GetCategoriesData?.isLoading} />
      <div className="flx_dv_prfl">
        <h2>{translation.profile.my_listing}</h2>
      </div>
      <div className="tabs listing_tabs">
        <h3
          className={tab === 1 ? "active" : ""}
          onClick={() => {
            setTab(1);
            setActiveCategory("");
          }}
        >
          {translation.Globals.pending}
        </h3>
        <h3
          className={tab === 2 ? "active" : ""}
          onClick={() => {
            setTab(2);
            setActiveCategory("");
          }}
        >
          {translation.Globals.accepted}
        </h3>
        <h3
          className={tab === 3 ? "active" : ""}
          onClick={() => {
            setTab(3);
            setActiveCategory("");
          }}
        >
          {translation.Globals.rejected}
        </h3>
      </div>
      {categories?.length ? (
        <ul className="categoty_fltrs new_catgry">
          <li
            onClick={() => setActiveCategory("")}
            className={
              activeCategory === "" ? "fltrs_cat actv_cat" : "fltrs_cat"
            }
          >
            <h4>{translation.Globals.all}</h4>
          </li>

          {categories?.map((item) => {
            return (
              <li
                key={item?._id}
                onClick={() => setActiveCategory(item?._id)}
                className={
                  activeCategory === item?._id
                    ? "fltrs_cat actv_cat"
                    : "fltrs_cat"
                }
              >
                <h4>
                  {language === "ar" ? item?.name_ar || "" : item?.name || ""}
                </h4>
              </li>
            );
          })}
        </ul>
      ) : null}

      <div className="main_listing_sctn">
        <div className="listing_sctn">
          {MyListing?.length ? (
            MyListing?.slice(0, 5)?.map((item) => {
              return (
                <ProductListingCard
                  setOpenModal={setOpenModal}
                  setSelectedId={setSelectedId}
                  key={item?._id}
                  product_id={item?._id}
                  product_image={item?.images[0]}
                  category_id={item?.category?._id}
                  product_feature={
                    language === "ar"
                      ? item?.category?.name_ar
                      : item?.category?.name
                  }
                  product_name={item?.name}
                  product_price={item?.price}
                  location={item?.address}
                  listing_date={item?.createdAt}
                  booster_applied={item?.boosterApplied}
                  lang={item?.lang}
                />
              );
            })
          ) : (
            <div className="noData">
              <figure>
                <img src="/static/images/empty.png" alt="empty" />
              </figure>
              <h3>{translation.Globals.no_product}</h3>
            </div>
          )}
        </div>
      </div>
      <div style={{ margin: "0 auto" }}>
        {MyListing?.length ? (
          <Pagination
            module={MyListing}
            page={page || 1}
            onPageChange={onPageChange}
            totalPages={totalPages}
            setPage={setPage}
          />
        ) : null}
      </div>
      <Modal
        // BackdropClick={false}

        open={openModal}
        onClose={() => setOpenModal(false)}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="AuthWrap">
            <div className="cross ">
              <CloseIcon onClick={() => setOpenModal(false)} />
            </div>
            <h2
              style={{
                textAlign: "center",
                marginTop: "-20px",
                fontSize: "22px",
              }}
            >
              {translation.profile.delete_product}
            </h2>
            <div className="logut_flx">
              <Button
                className="yesBtn"
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={(e: any) => {
                  setOpenModal(false);
                  handleDeleteProperty(e, selectedId);
                }}
              >
                {translation.Globals.yes}
              </Button>

              <Button
                sx={{ color: "#252525", border: "1px solid #7dbfdd" }}
                onClick={() => setOpenModal(false)}
              >
                {translation.Globals.no}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MyListing;

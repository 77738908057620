import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const SliderBanner = ({ sliderData }: any) => {
  const navigate = useNavigate();
  return (
    <section className="prodcts_sc container">
      <div className="prdct_hdngs ">
        <h2>{sliderData?.heading || ""}</h2>
      </div>
      <div className="manufacture_sc">
        {sliderData?.length ? (
          <Slider {...settings}>
            {sliderData?.map((item: any, i: number) => {
              return (
                <div className="manufacture_single" key={i}>
                  <figure>
                    <img src={item?.image} alt="" />
                  </figure>
                  <div className="inner_content">
                    <h5>{item?.heading || ""}</h5>
                    <p>{item?.content || ""}</p>
                    <a href={item?.button_link}>{item?.button_text || ""}</a>
                  </div>
                </div>
              );
            })}
          </Slider>
        ) : null}
      </div>
    </section>
  );
};

export default SliderBanner;

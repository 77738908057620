import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useParams } from "react-router-dom";
import { END_POINTS, WORDPRESS_URL } from "../../helpers";
import {
  WORDPRESS_PASSWORD,
  WORDPRESS_USERNAME,
} from "../../helpers/constants/urls";
import { Skeleton } from "@mui/material";

const BlogDetail = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [blog, setBlog] = useState<any>();

  const getBlogById = async (id: any) => {
    const url = `${WORDPRESS_URL}${END_POINTS.blogById}/${id}`;

    try {
      setLoading(true);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Basic ${btoa(
            `${WORDPRESS_USERNAME}:${WORDPRESS_PASSWORD}`
          )}`,
        },
      });
      console.log("Response received: ", response);
      if (response?.ok) {
        const responseData = await response.json();
        if (responseData?.status === 200) {
          setBlog(responseData?.data?.page_data);
          setLoading(false);
        }
      } else {
        console.log("Response not ok");
        setBlog(null);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      throw error;
    }
  };

  useEffect(() => {
    if (id) {
      getBlogById(id);
    }
  }, [id]);

  return (
    <Layout>
      {loading ? (
        <section className="blog_dtl blog_crd container">
          <Skeleton
            animation="wave"
            variant="rectangular"
            width={"100%"}
            height={"300px"}
          />
          <h2>
            {" "}
            <Skeleton
              variant="text"
              sx={{ fontSize: "2rem" }}
              width={"250px"}
              animation="wave"
            />
          </h2>
        </section>
      ) : (
        <section className="blog_dtl blog_crd container">
          <figure className="blog_dtl_img">
            <img src={blog?.main_image} alt="Blog Image" />
          </figure>
          <div className="blg_dt">
            <p>{blog?.date || ""}</p>
            <h5>{blog?.author || ""}</h5>
          </div>
          <h3 dangerouslySetInnerHTML={{ __html: blog?.heading || "" }} />
          <p dangerouslySetInnerHTML={{ __html: blog?.description || "" }} />
        </section>
      )}
    </Layout>
  );
};

export default BlogDetail;

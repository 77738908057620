// @ts-nocheck

import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Layout from "../../layout";
import { Pagination, ProductCard, ProductCardSkeleton } from "../../components";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";

import useTranslation from "../../hooks/Translation";
import { useParams } from "react-router-dom";

import { useLazyGetSellerQuery } from "../../service/Home";
import useAuth from "../../hooks/useAuth";
import SellerCard from "../../components/SellerCard";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";

const OfficialStores = () => {
  const user = useAuth();

  const translation = useTranslation() as any;
  const [loading, setLoading] = useState(true);
  const [getSellers] = useLazyGetSellerQuery();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState<number>(0);
  const [sellers, setSellers] = useState([]);
  const tempToken = useAppSelector(getTempToken);
  const token = getFromStorage(STORAGE_KEYS.token);

  let totalPages = Math.ceil(count / 85);

  const onPageChange = (newPage: number) => {
    let topElement = document.getElementById("top_corner");

    setTimeout(() => {
      topElement?.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
    setPage(newPage);
  };

  const handleVisitSeller = (id) => {
    if (user) {
      navigate(`/view-profile/${id}`);
    } else {
      dispatch(
        toggleAuthModal({ isAuthModalVisible: true, ModalType: "login" })
      );
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getSellers({
        page: page,
        size: 85,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSellers(response?.data?.data);
        setCount(response?.data?.count);
        setLoading(false);
      } else {
        setSellers([]);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (token || tempToken) {
      fetchData();
    }
  }, [page, tempToken]);

  return (
    <Layout>
      <div id="top_corner" />
      <div className="wrapCls">
        <div className="prodcts_sc  container whlt_sc">
          <h1
            style={{ fontSize: "26px", color: "#820000", marginBottom: "20px" }}
          >
            {translation.Globals.official_stores}
          </h1>
          <div className="crds_sec srch_sc_pg">
            {loading ? (
              Array.from({ length: 5 }, (_, index) => (
                <ProductCardSkeleton key={index} />
              ))
            ) : sellers?.length ? (
              sellers?.map((item) => {
                return (
                  <SellerCard
                    key={item?._id}
                    product_id={item?.userId?._id}
                    product_image={item?.logoImage}
                    product_name={item?.businessName || ""}
                  />
                );
              })
            ) : (
              <div className="noData">
                <figure>
                  <img src="/static/images/empty.png" alt="empty" />
                </figure>
                <h3>{translation.Globals.no_product}</h3>
              </div>
            )}
          </div>
        </div>
        <Pagination
          module={sellers}
          page={page || 1}
          onPageChange={onPageChange}
          totalPages={totalPages}
          setPage={setPage}
        />
      </div>
    </Layout>
  );
};

export default OfficialStores;

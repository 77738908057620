import { useEffect, useState } from "react";
import Layout from "../../layout";
import {
  Button,
  Pagination,
  ProductCard,
  ProductCardSkeleton,
} from "../../components";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Slider,
} from "@mui/material";
import "../filters/filter.scss";
import {
  useLazyGetProductsByCategoryIdQuery,
  useLazyGetSubCategoriesQuery,
} from "../../service/category";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProductsByCategory, Subcategories } from "../../types/General";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";

function valuetext(value: number) {
  return `${value}°C`;
}

const Filters = () => {
  const navigate = useNavigate();
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const location = useLocation();
  const { state } = location;

  const searchParams = new URLSearchParams(location.search);
  const name_ar = searchParams.get("name_ar");
  const name_en = searchParams.get("name_en");
  const subCat = searchParams.get("subCat");
  const brandsFromURL = searchParams.get("brand");
  const brand = brandsFromURL ? brandsFromURL?.split(",") : [];

  const sort = searchParams.get("sort");
  const minPriceValue = Number(searchParams.get("minPrice"));
  let maxPriceValue = Number(searchParams.get("maxPrice"));
  const pageValue = Number(searchParams.get("page"));

  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);
  const [favItems, setFavItems] = useState<any[]>([]);
  const { id } = useParams();
  const [selectedValue, setSelectedValue] = useState("");
  const [page, setPage] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(1000000000);
  const [value, setValue] = useState<number[]>([0, maxPrice]);
  const [loading, setLoading] = useState(true);
  const [productsByCategory, setProductsByCategory] = useState<
    ProductsByCategory[]
  >([]);
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<Subcategories | null>(null);

  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState("");

  const [subCategories, setSubCategories] = useState<Subcategories[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);

  const [productsByCategoryId] = useLazyGetProductsByCategoryIdQuery();
  const [subCategory] = useLazyGetSubCategoriesQuery();

  const sortingOptiopns = [
    { value: "new", label: translation.Globals.new },
    { value: "popular", label: translation.Globals.popular },
    { value: "lowToHigh", label: translation.Globals.low_high },
    { value: "highToLow", label: translation.Globals.high_low },
  ];

  const handleChange = (event: Event, newValue: any) => {
    setValue(newValue as any);

    navigate(
      `/products/${id}?name_ar=${name_ar}&name_en=${name_en}&subCat=${selectedSubcategoryId}&brand=${
        selectedBrands?.length ? selectedBrands?.join(",") : ""
      }&sort=${selectedValue}&minPrice=${newValue[0]}&maxPrice=${
        newValue[1]
      }&page=${page}`,
      { replace: true }
    );
  };

  const checkFavProducts = () => {
    const fav_data = productsByCategory
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);

    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProducts();
  }, [productsByCategory]);

  const handleBrandToggle = (brand: string) => {
    // Toggle the selected state of the brand
    setSelectedBrands((prevSelectedBrands) => {
      if (prevSelectedBrands.includes(brand)) {
        return prevSelectedBrands.filter(
          (selectedBrand) => selectedBrand !== brand
        );
      } else {
        return [...prevSelectedBrands, brand];
      }
    });
    let stateData = {
      id,
      minPriceValue: value?.[0],
      maxPriceValue: value?.[1],
      subCat: selectedSubcategoryId,
      sort: selectedValue,
      pageValue: page,
      brands: selectedBrands.includes(brand)
        ? selectedBrands.filter((selectedBrand) => selectedBrand !== brand)
        : [...selectedBrands, brand],
    };
    getProductsByCategory(stateData);
    const brandList = selectedBrands.includes(brand)
      ? selectedBrands.filter((selectedBrand) => selectedBrand !== brand)
      : [...selectedBrands, brand];

    const selectedBrandsList = brandList?.length ? brandList.join(",") : "";

    navigate(
      `/products/${id}?name_ar=${name_ar}&name_en=${name_en}&subCat=${selectedSubcategoryId}&brand=${selectedBrandsList}&sort=${selectedValue}&minPrice=${
        value[0]
      }&maxPrice=${value[1]}&page=${1}`,
      { replace: true }
    );
  };

  const handleSubcategoryClick = (subcategory: any) => {
    setSelectedSubcategory(subcategory);
    setSelectedSubcategoryId(subcategory?._id);
    setSelectedBrands([]);
    setPage(1);
    let stateData = {
      id,
      minPriceValue: value?.[0],
      maxPriceValue: value?.[1],
      subCat: subcategory?._id,
      sort: selectedValue,
      pageValue: 1,
      brands: selectedBrands,
    };
    getProductsByCategory(stateData);

    const subcategoryId = subcategory ? subcategory?._id : null;

    navigate(
      `/products/${id}?name_ar=${name_ar}&name_en=${name_en}&subCat=${subcategoryId}&brand=${
        selectedBrands?.length ? selectedBrands?.join(",") : ""
      }&sort=${selectedValue}&minPrice=${value[0]}&maxPrice=${
        value[1]
      }&page=${1}`,
      { replace: true }
    );
  };
  const handleSortingOptions = (event: any) => {
    setSelectedValue(event.target.value);
    let stateData = {
      id,
      minPriceValue: value?.[0],
      maxPriceValue: value?.[1],
      subCat: selectedSubcategoryId,
      sort: event.target.value,
      pageValue: page,
      brands: selectedBrands,
    };
    getProductsByCategory(stateData);
    navigate(
      `/products/${id}?name_ar=${name_ar}&name_en=${name_en}&subCat=${selectedSubcategoryId}&brand=${
        selectedBrands?.length ? selectedBrands?.join(",") : ""
      }&sort=${event.target.value}&minPrice=${value[0]}&maxPrice=${
        value[1]
      }&page=${page}`,
      { replace: true }
    );
  };

  const getProductsByCategory = async (stateData?: any) => {
    setLoading(true);
    try {
      const response = await productsByCategoryId({
        categoryId: stateData?.id,
        page: stateData?.pageValue,
        minPrice: stateData?.minPriceValue ? stateData?.minPriceValue : 0,
        maxPrice: stateData?.maxPriceValue
          ? stateData?.maxPriceValue
          : 10000000000,
        sort: stateData?.sort,
        brand: stateData?.brands || [],
        subCategoryId: stateData?.subCat ? stateData?.subCat : "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setProductsByCategory(response?.data?.products || []);
        setTotalCount(response?.data?.count);
        setMaxPrice(response?.data?.maxPrice || 100000000);
        setLoading(false);
      } else {
        setProductsByCategory([]);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);

      errorToast(error?.data?.message || "");
    }
  };
  const handlePriceFilter = () => {
    if (id) {
      let stateData = {
        id,
        minPriceValue: value?.[0],
        maxPriceValue: value?.[1],
        subCat: selectedSubcategoryId,
        sort: selectedValue,
        pageValue: page,
        brands: selectedBrands,
      };
      getProductsByCategory(stateData);
    }
  };

  const getSubCategory = async (id: string) => {
    try {
      const response = await subCategory({
        categoryId: id,
        page: 1,
        limit: 50,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategories(response?.data?.subCategory || []);
      } else {
        setSubCategories([]);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  let totalPages = Math.ceil(totalCount / 68);

  const onPageChange = (newPage: number) => {
    let topElement = document.getElementById("top_corner");

    setTimeout(() => {
      topElement?.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
    setPage(newPage);
    let stateData = {
      id,
      minPriceValue: value[0],
      maxPriceValue: value[1],
      subCat: selectedSubcategoryId,
      sort: selectedValue,
      pageValue: newPage,
      brands: selectedBrands,
    };
    getProductsByCategory(stateData);
    navigate(
      `/products/${id}?name_ar=${name_ar}&name_en=${name_en}&subCat=${selectedSubcategoryId}&brand=${
        selectedBrands?.length ? selectedBrands?.join(",") : ""
      }&sort=${selectedValue}&minPrice=${value[0]}&maxPrice=${
        value[1]
      }&page=${newPage}`,
      { replace: true }
    );
  };

  const handleClearFilters = () => {
    setSelectedValue("");
    setSelectedSubcategory(null);
    setSelectedSubcategoryId("");
    setSelectedBrands([]);
    setValue([0, 1000000000]);
    setPage(1);

    let stateData = {
      id,
      minPriceValue: 0,
      maxPriceValue: 1000000000,
      subCat: "",
      sort: "",
      pageValue: 1,
      brands: [],
    };
    getProductsByCategory(stateData);
    navigate(
      `/products/${id}?name_ar=${name_ar}&name_en=${name_en}&subCat=${""}&brand=${""}&sort=${""}&minPrice=${0}&maxPrice=${1000000000}&page=${1}`,
      { replace: true }
    );
  };

  const handleMobileFilters = () => {
    if (id) {
      let stateData = {
        id,
        minPriceValue: value?.[0],
        maxPriceValue: value?.[1],
        subCat: selectedSubcategoryId,
        sort: selectedValue,
        pageValue: page,
        brands: selectedBrands,
      };
      getProductsByCategory(stateData);
    }
    setShowFilters(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (id && (token || tempToken)) {
      let stateData = {
        id,
        minPriceValue,
        maxPriceValue,
        subCat,
        sort,
        pageValue,
        brands: selectedBrands,
      };
      getProductsByCategory(stateData);
    }
  }, [id, state, tempToken]);

  useEffect(() => {
    if (id && (token || tempToken)) {
      setValue([minPriceValue, maxPriceValue]);

      setSelectedValue(sort || "");
      if (state?.selectedSubcategory) {
        setSelectedSubcategory(state.selectedSubcategory);
      } else {
        // Find the subcategory object from subCategories array based on its _id
        const selectedSubcategoryFromId = subCategories?.find(
          (subCategory) => subCategory._id === subCat
        );
        setSelectedSubcategory(selectedSubcategoryFromId || null);
      }
      setSelectedSubcategoryId(subCat || "");
      getSubCategory(id);
      setPage(pageValue);
      setSelectedBrands(brand);
    }
  }, [id, tempToken, maxPrice, state]);

  return (
    <Layout>
      <div id="top_corner" />
      <div className="wrapCls">
        <div className="container">
          <div className="filters">
            <div className="filters_dv">
              <h2>{language === "ar" ? name_ar || "" : name_en || ""}</h2>
              <figure>
                <img
                  src="/static/images/filter_icn.svg"
                  onClick={() => setShowFilters(!showFilters)}
                  alt="filter_icn"
                />
              </figure>
            </div>
            <div className="sub_catMain">
              <div className={showFilters ? "sc_lft mob_dsply" : "sc_lft "}>
                <div className="inner">
                  <div className="sub">
                    <div className="filtr_top_hdng">
                      <h4>{translation.Globals.sub_cat}</h4>
                      {subCat || selectedBrands?.length || sort ? (
                        <p onClick={() => handleClearFilters()}>
                          {translation.Globals.clear_all}
                        </p>
                      ) : null}
                    </div>
                    <ul>
                      {loading ? (
                        <li>
                          {Array.from({ length: 4 }, (_, index) => (
                            <Skeleton
                              key={index}
                              variant="text"
                              style={{
                                fontSize: "1rem",
                                width: "100px",
                                paddingBottom: "4px",
                              }}
                            />
                          ))}
                        </li>
                      ) : subCategories?.length ? (
                        subCategories?.map((item) => {
                          return (
                            <li
                              key={item?._id}
                              onClick={() => handleSubcategoryClick(item)}
                              className={
                                selectedSubcategoryId === item?._id
                                  ? "actv_cat"
                                  : ""
                              }
                            >
                              {language === "ar"
                                ? item?.name_ar || ""
                                : item?.name || ""}
                            </li>
                          );
                        })
                      ) : null}
                    </ul>
                  </div>
                  {/* Render Brand list in english  only if a subcategory is selected */}
                  {language === "en" &&
                  selectedSubcategory &&
                  selectedSubcategory?.brand?.length ? (
                    <div className="sub">
                      <h3>{translation.Globals.brand}</h3>
                      <ul>
                        {selectedSubcategory?.brand?.map((brand) => (
                          <li key={brand}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedBrands?.includes(brand)}
                                  onChange={() => handleBrandToggle(brand)}
                                />
                              }
                              name={brand}
                              label={brand || ""}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  {language === "ar" &&
                  selectedSubcategory &&
                  selectedSubcategory?.brand_ar?.length ? (
                    <div className="sub">
                      <h3>{translation.Globals.brand}</h3>
                      <ul>
                        {selectedSubcategory?.brand_ar?.map((brand) => (
                          <li key={brand}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedBrands?.includes(brand)}
                                  onChange={() => handleBrandToggle(brand)}
                                />
                              }
                              name={brand}
                              label={brand || ""}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
                  <div className="sub">
                    <h3>{translation.Globals.sort}</h3>
                    <ul className="sort_options">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedValue}
                        onChange={handleSortingOptions}
                      >
                        {sortingOptiopns?.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </ul>
                  </div>

                  <div className="sub">
                    <h3>{translation.Globals.price}</h3>
                    <ul style={{ paddingTop: 0 }}>
                      <li>
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetext}
                          max={maxPrice}
                        />
                        <div
                          style={{
                            marginTop: 10,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                            {translation.Globals.aed}{" "}
                            {value[0].toLocaleString("en-IN")}
                          </span>
                          <span style={{ color: "#0D0D0D", fontSize: "14px " }}>
                            {translation.Globals.aed}{" "}
                            {value[1].toLocaleString("en-IN")}
                          </span>
                        </div>
                        <div className="fltr_btn_sc">
                          <button
                            className="fltr_btnn"
                            onClick={handlePriceFilter}
                          >
                            {translation.Globals.apply}
                          </button>
                        </div>
                        <div className="mob_fltr_btn">
                          <Button
                            value={translation.Globals.apply_filter}
                            onClick={() => handleMobileFilters()}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className={showFilters ? "sc_Ryt hide_prdcts" : "sc_Ryt"}>
                <div className="prodcts_sc">
                  <div className="crds_sec">
                    {loading ? (
                      Array.from({ length: 9 }, (_, index) => (
                        <ProductCardSkeleton key={index} />
                      ))
                    ) : productsByCategory?.length ? (
                      productsByCategory?.map((item) => {
                        return (
                          <ProductCard
                            key={item?._id}
                            product_id={item?._id}
                            product_image={item?.images[0]}
                            product_feature={
                              language === "ar"
                                ? item?.category?.name_ar
                                : item?.category?.name
                            }
                            product_name={item?.name}
                            product_price={item?.price}
                            booster_applied={item?.boosterApplied}
                            location={item?.address}
                            favItems={favItems}
                            item={item}
                            setFavItems={setFavItems}
                            lang={item?.lang}
                          />
                        );
                      })
                    ) : (
                      <div className="noData top_mt">
                        <figure>
                          <img src="/static/images/empty.png" alt="empty" />
                        </figure>
                        <h3>{translation.Globals.no_product}</h3>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={showFilters ? " hide_prdcts" : ""}>
              {productsByCategory?.length ? (
                <Pagination
                  module={productsByCategory}
                  page={page || 1}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Filters;

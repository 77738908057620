import React, { useEffect, useState, useTransition } from "react";
import Layout from "../../layout";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import LogoHeader from "../../layout/logoHeader";
import { useLazyGetCategoriesQuery } from "../../service/Home";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../helpers";
import { Category } from "../../types/General";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";
import useTranslation from "../../hooks/Translation";
import { Skeleton } from "@mui/material";

const ChooseCategory = () => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const navigate = useNavigate();

  const token = getFromStorage(STORAGE_KEYS.token);

  const tempToken = useAppSelector(getTempToken);

  const [getCategories] = useLazyGetCategoriesQuery();

  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState(true);

  const getAllCategories = async () => {
    setLoading(true);
    try {
      const response = await getCategories({}).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.category || []);
        setLoading(false);
      } else {
        setCategories([]);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (token || tempToken) {
      getAllCategories();
    }
  }, [tempToken]);

  return (
    <>
      <LogoHeader />
      {/* <Loader isLoad={isLoading || loading} /> */}
      <div className="wrapCls">
        <div className="container">
          <div className="choose">
            <h2>{translation.Globals.choose_cat}</h2>
            {loading ? (
              <div className="options">
                <ul>
                  {Array.from({ length: 7 }, (_, index) => (
                    <li key={index}>
                      <div className="cate_name">
                        <figure
                          style={{
                            borderRadius: "50%",
                            marginRight: language === "ar" ? "0" : "20px",
                            marginLeft: language === "ar" ? "20px" : "0px",
                          }}
                        >
                          <Skeleton
                            variant="circular"
                            animation="wave"
                            height={"100%"}
                            width={"100%"}
                          />
                        </figure>

                        <h3>
                          {" "}
                          <Skeleton
                            variant="text"
                            animation="wave"
                            width={"300px"}
                            style={{ fontSize: "1rem" }}
                          />
                        </h3>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : categories?.length ? (
              <div className="options">
                <ul>
                  {categories?.length
                    ? categories?.map((item) => (
                        <li
                          key={item?._id}
                          onClick={() => navigate(`/add-product/${item?._id}`)}
                        >
                          <div className="cate_name">
                            {item?.image?.length ? (
                              <figure
                                style={{
                                  borderRadius: "50%",
                                  marginRight: language === "ar" ? "0" : "20px",
                                  marginLeft:
                                    language === "ar" ? "20px" : "0px",
                                }}
                              >
                                <img
                                  style={{
                                    borderRadius: "50%",
                                  }}
                                  src={item?.image[0] || ""}
                                  alt="category"
                                />
                              </figure>
                            ) : null}

                            <h3>
                              {language === "ar" ? item?.name_ar : item?.name}
                            </h3>
                          </div>
                          <ArrowForwardIosIcon />
                        </li>
                      ))
                    : null}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseCategory;

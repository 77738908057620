import React, { useEffect, useState } from "react";
import { ProductCard, ProductCardSkeleton } from "../../components";
import { RecommendedProductsData } from "../../types/General";
import { Link, useNavigate } from "react-router-dom";
import useTranslation from "../../hooks/Translation";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type props = {
  recommendedProducts: RecommendedProductsData[];
  loading: boolean;
};

const settings = {
  dots: false,
  arrows: true,
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 7,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const RecommendedProducts = ({ recommendedProducts, loading }: props) => {
  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [favItems, setFavItems] = useState<any[]>([]);

  const checkFavProperties = () => {
    const fav_data = recommendedProducts
      .filter((item: any) => item?.isFav)
      .map((item: any) => item);
    setFavItems(fav_data);
  };

  useEffect(() => {
    checkFavProperties();
  }, [recommendedProducts]);

  return (
    <>
      {loading ? (
        <section className="prodcts_sc container">
          <div className="crds_sec">
            {Array.from({ length: 5 }, (_, index) => (
              <ProductCardSkeleton key={index} />
            ))}
          </div>
        </section>
      ) : (
        <section className="prodcts_sc container rcmd_pr">
          <div className="prdct_hdngs ">
            <h2>{translation.Globals.recommended_products}</h2>
            {recommendedProducts?.length > 5 ? (
              <Link to={`/all-products/${"recommended"}?sort=${"alpha"}`}>
                {" "}
                <p>{translation.Globals.see_all}</p>
              </Link>
            ) : null}
          </div>
          {/* <div className="crds_sec"> */}
          {recommendedProducts?.length ? (
            <Slider {...settings}>
              {recommendedProducts?.map((item) => {
                return (
                  <ProductCard
                    key={item?._id}
                    product_id={item?._id}
                    product_image={item?.images[0]}
                    product_feature={
                      language === "ar"
                        ? item?.category?.name_ar
                        : item?.category?.name
                    }
                    product_name={item?.name}
                    product_price={item?.price}
                    location={item?.address}
                    setFavItems={setFavItems}
                    booster_applied={item?.boosterApplied}
                    favItems={favItems}
                    item={item}
                    loading={loading}
                    lang={item?.lang}
                  />
                );
              })}
            </Slider>
          ) : (
            <div className="noData">
              <figure>
                <img src="/static/images/empty.png" alt="empty" />
              </figure>
              <h3>{translation.Globals.no_product}</h3>
            </div>
          )}
          {/* </div> */}
        </section>
      )}
    </>
  );
};

export default RecommendedProducts;

import { useEffect, useState } from "react";
import Layout from "../../layout";
import { Pagination, ProductCard, ProductCardSkeleton } from "../../components";
import { useLazyGetSearchOnNextPageQuery } from "../../service/Product";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../helpers";
import { RecommendedProductsData } from "../../types/General";
import useTranslation from "../../hooks/Translation";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../hooks/store";
import { getTempToken } from "../../reducers/authSlice";

const SearchedProducts = () => {
  const { search } = useParams();
  const token = getFromStorage(STORAGE_KEYS.token);
  const tempToken = useAppSelector(getTempToken);

  const language = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation() as any;
  const [loading, setLoading] = useState(true);
  const [getProducts] = useLazyGetSearchOnNextPageQuery();
  const [page, setPage] = useState(1);
  const [productCount, setProductCount] = useState<number>(0);
  const [searchedProducts, setSearchedProducts] = useState<
    RecommendedProductsData[]
  >([]);

  let totalPages = Math.ceil(productCount / 85);

  const onPageChange = (newPage: number) => {
    let topElement = document.getElementById("top_corner");

    setTimeout(() => {
      topElement?.scrollIntoView({
        behavior: "smooth",
      });
    }, 500);
    setPage(newPage);
  };

  const getSearchedProducts = async (search: string) => {
    setLoading(true);
    try {
      const response = await getProducts({
        page: page,
        size: 85,
        search: search,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSearchedProducts(response?.data?.productSearch || []);
        setProductCount(response?.data?.count);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      errorToast(error?.data?.message || "");
    }
  };

  const [favItems, setFavItems] = useState<any[]>([]);

  useEffect(() => {
    if (search && (tempToken || token)) {
      getSearchedProducts(search);
    }
  }, [search, page, tempToken]);

  return (
    <Layout>
      <div id="top_corner" />
      <div className="wrapCls">
        <div className="prodcts_sc  container whlt_sc">
          <h1
            style={{ fontSize: "26px", color: "#820000", marginBottom: "20px" }}
          >
            Searches for "{search}"
          </h1>
          <div className="crds_sec srch_sc_pg">
            {loading ? (
              Array.from({ length: 5 }, (_, index) => (
                <ProductCardSkeleton key={index} />
              ))
            ) : searchedProducts?.length ? (
              searchedProducts?.map((item) => {
                return (
                  <ProductCard
                    wishlist={item?.isFav}
                    key={item?._id}
                    product_id={item?._id}
                    product_image={item?.images[0]}
                    product_feature={
                      language === "ar"
                        ? item?.category?.name_ar
                        : item?.category?.name
                    }
                    product_name={item?.name}
                    product_price={item?.price}
                    location={item?.address}
                    booster_applied={item?.boosterApplied}
                    setFavItems={setFavItems}
                    favItems={favItems}
                    item={item}
                    lang={item?.lang}
                  />
                );
              })
            ) : (
              <div className="noData">
                <figure>
                  <img src="/static/images/empty.png" alt="empty" />
                </figure>
                <h3>{translation.Globals.no_product}</h3>
              </div>
            )}
          </div>
        </div>
        <Pagination
          module={searchedProducts}
          page={page || 1}
          onPageChange={onPageChange}
          totalPages={totalPages}
          setPage={setPage}
        />
      </div>
    </Layout>
  );
};

export default SearchedProducts;

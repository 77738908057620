import React from "react";

const BlogBanner = ({ bannerData }: any) => {
  return (
    <section className="home_banner_sc">
      {/* <a
        key={item?._id}
        href={
          item?.link?.startsWith("http") ? item?.link : `https://${item?.link}`
        }
        target="_blank"
        rel="noopener noreferrer"
      > */}
      <div
        className="blog_bnr"
        style={{
          backgroundImage: `url(${bannerData?.image})`,
        }}
      >
        <div className="container">
          <h1 className="bnr_cntnt">{bannerData?.heading || ""}</h1>
        </div>
      </div>
      {/* </a> */}
    </section>
  );
};

export default BlogBanner;
